@import 'mixins.scss';

@mixin listingDark {
  --bgListing: rgb(13, 17, 23);
  --boxshadowListing: rgba(255,255,255,.15);
  --boxshadowImage: 0 0 2em rgba(255,255,255,.1);
  --bgImage: rgba(255,255,255,.1);
  --bgImagePill: rgba(255,255,255,.3);
  --bgImagePillSelected: rgba(255,255,255,.5);
  --dropBorder: #EE3942;
  --dropBg: rgba(238, 57, 66, .1);
  --invertPrivacyIcon: invert(1);
}

:root {
  --bgListing: #fff;
  --boxshadowListing: rgba(0,0,0,.2);
  --boxshadowImage: 0 0 2em rgba(0,0,0,.25);
  --bgImage: rgba(0,0,0,.075);
  --bgImagePill: rgba(0,0,0,.15);
  --bgImagePillSelected: rgba(0,0,0,.4);
  --dropBorder: rgb(238, 57, 66);
  --dropBg: rgba(238, 57, 66, .1);
  --invertPrivacyIcon: none;
}
body.dark {
  @include listingDark;
}
@media (prefers-color-scheme: dark) {
  body:not(.light) {
    :root {
      @include listingDark;
    }
  }
}

.grade-container {
  display: flex;
  align-items: center;
}

.grade-verified {
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 1em;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 1.5em;
    height: 1.5em;
    background-image: url('/img/icons/graded.svg');
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: .25em;
  }
}

.grade {
  display: inline-block;
  background: #ccc;
  color: var(--bgListing);
  padding: .1em .5em;
  border-radius: 2px;
  align-items: center;
  display: flex;
  white-space: nowrap;

  span {
    display: inline-block;
  }

  .score {
    font-size: 1.2em;
    font-weight: 600;
  }

  .text {
    margin-left: .4em;
    font-size: .8em;
    font-weight: 300;
  }

  &.grade-100, &.grade-90 {
    // s
    background-color: rgb(34, 34, 34);
  }
  &.grade-80 {
    // s
    background-color: rgb(115, 17, 17);
  }
  &.grade-70 {
    // a
    background-color: rgb(195, 0, 0);
  }
  &.grade-60 {
    // a
    background-color: rgb(169, 46, 122);
  }
  &.grade-50 {
    // b
    background-color: rgb(143, 93, 224);
  }
  &.grade-40, &.grade-30, &.grade-20 {
    // c
    background-color: rgb(30, 172, 224);
  }
  &.grade-15, &.grade-10 {
    // f
    background-color: rgb(131, 170, 100);
  }
  &.none {
    background-color: rgb(180, 180, 180);
    cursor: help;
    font-size: .8em;
  }
}

ul.listings:not(.single) {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: auto;

  display: grid;
  // flex-wrap: wrap;

  grid-gap: 4px;
  @media (min-width: 600px) {
    grid-gap: .5em;
  }

  // li {
  //   @media (max-width: 350px) {
  //     width: calc(100%/2 - 4px);
  //   }
  //   @media (min-width: 351px) and (max-width: 499px) {
  //     width: calc(100%/3 - 4px);
  //   }
  //   @media (min-width: 500px) and (max-width: 599px) {
  //     width: calc(100%/4 - 4px);
  //   }
  //   @media (min-width: 600px) and (max-width: 719px) {
  //     width: calc(100%/4 - .5em);
  //   }
  //   @media (min-width: 720px) and (max-width: 959px) {
  //     width: calc(100%/5 - .5em);
  //   }
  //   @media (min-width: 960px) and (max-width: 1219px) {
  //     width: calc(100%/6 - .5em);
  //   }
  //   @media (min-width: 1220px) {
  //     width: calc(100%/8 - .5em);
  //   }
  // }

  grid-template-columns: repeat(auto-fill, calc(100%/2 - 2px));
  
  @media (min-width: 351px) and (max-width: 499px) {
    grid-template-columns: repeat(auto-fill, calc(100%/3 - 3px));
  }
  @media (min-width: 500px) and (max-width: 599px) {
    grid-template-columns: repeat(auto-fill, calc(100%/4 - 3px));
  }
  @media (min-width: 600px) and (max-width: 719px) {
    grid-template-columns: repeat(auto-fill, calc(100%/4 - 5px));
  }
  @media (min-width: 720px) and (max-width: 959px) {
    grid-template-columns: repeat(auto-fill, calc(100%/5 - 5px));
  }
  @media (min-width: 960px) and (max-width: 1219px) {
    grid-template-columns: repeat(auto-fill, calc(100%/6 - 8px));
  }
  @media (min-width: 1220px) {
    grid-template-columns: repeat(auto-fill, calc(100%/8 - 8px));
  }

  justify-content: space-between;
  
  // &:after {
  //   content: "";
  //   flex: auto;
  // }

}

ul.listings:not(.single).organising {
  display: block;
  grid-template-columns: none;
  justify-content: unset;
  overflow-y: hidden;

  .listing {
    width: calc(100%/2);
    overflow: hidden;

    // &:before {
    //   content: "";
    //   display: block;
    //   position: absolute;
    //   width: 100%;
    //   height: 100%;
    //   border: 2px dashed var(--boxshadowListing);
    //   box-sizing: border-box;
    //   border-radius: inherit;
    //   z-index: 1;
    // }

    > div {
      border-radius: inherit;

      &:before {
        box-shadow: none;
        content: "";
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 2px dashed var(--boxshadowListing);
        box-sizing: border-box;
        border-radius: inherit;
        z-index: 1;
      }
    }

    @media (min-width: 351px) and (max-width: 499px) {
      width: calc(100%/3);
    }
    @media (min-width: 500px) and (max-width: 599px) {
      width: calc(100%/4);
    }
    @media (min-width: 600px) and (max-width: 719px) {
      width: calc(100%/4);
    }
    @media (min-width: 720px) and (max-width: 959px) {
      width: calc(100%/5);
    }
    @media (min-width: 960px) and (max-width: 1219px) {
      width: calc(100%/6);
    }
    @media (min-width: 1220px) {
      width: 150px;
    }

    display: block;
    float: left;
    cursor: move;
  }

  .packery-drop-placeholder {
    padding-right: 8px;
    padding-bottom: 8px;
    box-sizing: border-box;

    &:before {
      content: "";
      display: block;
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border: 2px dashed var(--dropBorder);
      border-radius: 0.65em;
      /* transition position changing */
      -webkit-transition: -webkit-transform 0.2s;
        transition: transform 0.2s;
      background: var(--dropBg);
      
      animation: packeryDropPlaceholder .25s ease-in-out infinite alternate;
    }
  }
}

@keyframes packeryDropPlaceholder {
  0% {
    opacity: .25;
  } 100% {
    opacity: 1;
  }
}

.listings .listing {
  position: relative;
  $borderRadius: .65em;
  background: var(--bgListing);

  list-style-type: none;
  display: inline-block;
  border-radius: $borderRadius;
  box-sizing: border-box;
  cursor: pointer;
  // flex-grow: 1;

  user-select: none;

  // margin-bottom: 4px;
  // @media (min-width: 600px) {
  //   margin-bottom: .5em;
  // }

  > * {
    position: relative;
    display: block;
    text-decoration: none;
    color: inherit;

    &:before {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      box-shadow: inset 0 0 0 1px var(--boxshadowListing);
      border-radius: $borderRadius;
      z-index: 5;
      pointer-events: none;
    }
  }

  &.for-sale:not(.userImages) .images img {
    opacity: .5;
  }

  &.for-sale .no-images {
    position: absolute;
    top: 33%;
    width: 100%;
    text-align: center;
    padding: .5em 0;
    background: rgba(255,255,255,.8);
    z-index: 1;
  }

  transition: box-shadow .25s linear;

  &.is-pointer-down, &.is-positioning-post-drag {
    z-index: 100;
  }
  &.is-pointer-down {
    box-shadow: 0 4px 16px rgba(0,0,0,.4);
    cursor: grabbing;
  }

  .images {
    opacity: 0;
    transition: all .25s linear;
    position: relative;
    width: 100%;
    padding-bottom: (8.8/6.3 * 100%);
    background: var(--bgImage);
    border-radius: calc($borderRadius * 1.05) calc($borderRadius * 1.05) 0 0;
    overflow: hidden;

    > div {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.multiple {
      cursor: pointer;
    }
    &.loaded, &.none {
      opacity: 1;
    }
    img {
      position: absolute;
      margin: 0;
      max-width: 100%;
      max-height: 100%;
      box-shadow: var(--boxshadowImage);
    }
    .no-image {
      position: absolute;
      display: block;
      color: rgba(0,0,0,0);
      font-size: 0;
      width: 100%;
      padding-bottom: 141.42857143%;
      background-image: url('/no_image.png');
      background-size: 100% auto;
      // opacity: .75;
      opacity: var(--no-image-opacity);
    }
  }

  .image-navigation {
    height: 1em;
    display: flex;
    justify-content: center;
    padding: 0 1em;
    gap: 0;

    button {
      position: relative;
      background: none;
      height: 100%;
      padding: 0;
      width: 1em;
      border: 0;
      border-radius: 0;
      display: flex;
      align-items: center;

      &:before {
        content: "";
        display: block;
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background: var(--bgImagePill);
        left: .375em;
        // margin-left: -2px;
        // transform: translateX(2px);
      }

      &.selected:before {
        background: var(--bgImagePillSelected);
      }

      // &:first-of-type {
      //   &:not(:last-of-type) {
      //     width: 2em;
      //     &:before {
      //       left: auto;
      //       margin-left: auto;
      //       right: .375em;
      //       margin-right: -2px;
      //     }
      //   }
      // }
      // &:last-of-type {
      //   &:not(:first-of-type) {
      //     width: 2em;
      //   }
      // }
    }
  }

  .bottom {
    border-top: 0;
    border-radius: 0 0 $borderRadius $borderRadius;
    padding: 0 2vw 2vw;

    @media (min-width: 600px) {
      padding: 0 .75em .75em;
    }
  }

  h3, div > span {
    font-size: .7em;
    font-weight: inherit;
    margin: 0;
  }

  .item-name {
    display: flex;
    height: 2.6em;
    margin-bottom: .4rem;
    justify-content: center;
    align-items: center;
    text-align: center;

    > * {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .item-details {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 1.2em;

    &.price-digits-7, &.price-digits-8, &.price-digits-9 {
      .grade .text {
        display: none;
      }
      .price {
        font-size: 1em;
      }
    }
  }

  @media (min-width: 350px) and (max-width: 420px),
    (min-width: 500px) and (max-width: 620px),
    (min-width: 720px) and (max-width: 780px) {
    .item-details {
      &.price-digits-5 {
        .grade .text {
          display: none;
        }
        .price {
          font-size: 1em;
        }
      }
    }
  }

  @media (min-width: 351px) and (max-width: 680px),
    (min-width: 720px) and (max-width: 880px),
    (min-width: 960px) and (max-width: 1050px),
    (min-width: 1220px) and (max-width: 1400px) {
    .item-details {
      &.price-digits-6 {
        .grade .text {
          display: none;
        }
        .price {
          font-size: 1em;
        }
      }
    }
  }

  .platform {
    position: relative;
    display: inline-block;
    background: top left no-repeat;
    background-size: contain;
    color: transparent;
    height: 2em;
    width: 4em;

    &.tgcshop193net {
      background-image: url('/img/platforms/tgcshop193net.gif');
      border-radius: 50%;
    }
    &.cardmax {
      background-image: url('/img/platforms/cardmax.png');
    }
    &.cardrush {
      background-image: url('/img/platforms/cardrush.png');
    }
    &.cardshopbeems {
      background-image: url('/img/platforms/cardshopbeems.png');
    }
    &.clabo {
      background-image: url('/img/platforms/clabo.gif');
    }
    &.dragonstar {
      background-image: url('/img/platforms/dragonstar.png');
    }
    &.fullcomp {
      background-image: url('/img/platforms/fullcomp.png');
    }
    &.hareruya2 {
      background-image: url('/img/platforms/hareruya2.gif');
      border-radius: 2px;
    }
    &.masterssquare {
      background-image: url('/img/platforms/masterssquare.png');
    }
    &.mercari {
      background-image: url('/img/platforms/mercari.svg');
    }
    &.misendo {
      background-image: url('/img/platforms/misendo.png');
    }
    &.spiral {
      background-image: url('/img/platforms/spiral.png');
    }
    &.yuyutei {
      background-image: url('/img/platforms/yuyutei.png');
    }
  }

  .price {
    display: inline-block;
    font-size: 1.2em;
    margin-left: .1em;
    .number {
      font-weight: 600;
    }
    .symbol {
      font-size: .7em;
    }
  }

  &.private {
    opacity: .67;
  }

  .private {
    display: block;
    background: url('/img/icons/private.svg') no-repeat;
    background-size: contain;
    background-position: center;
    width: 2em;
    height: 2em;

    filter: var(--invertPrivacyIcon);
  }
  
  &.sold a:after {
    content: "";
    position: absolute;
    display: block;
    width: 50%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 6;
    border-radius: calc($borderRadius * 1.05) 0 0 0;

    background: url('/sold.svg') no-repeat;
  }
}
