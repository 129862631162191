$colour_red: #EE3942;
$color_lightred: #F9C3C4;
$color_gold: #F6C22E;

$color_disabled: #2AB61D;
$color_green: #2AB61D;

$color_lightgrey: #ddd;
$color_medgrey: #bbb;

$color_greyfont: #666;

$header_height_desktop: 6.5vw;
$header_height_desktop_max: 62px;

$header_height_mobile: 15vw;
$footer_height_mobile: 10vh;
