@import 'mixins.scss';

@mixin photosDark {
  --colorNumber: #000;
}
:root {
  --colorNumber: #fff;
}

body.dark {
  @include photosDark;
}

@media (prefers-color-scheme: dark) {
  body:not(.light) {
    :root {
      @include photosDark;
    }
  }
}

.photos {
  .intro {
    @media (min-width: 500px) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 1em;

      .left {
        width: 50vw;
      }
  
      .right {
        position: relative;
        width: 50vw;
        max-width: 600px;
        height: 30vh;
        max-height: 350px;
        background: url("/hero/search.svg") no-repeat center center;
        background-size: auto auto;
      }
    }
  }

  .past-matches {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;

    &::after {
      content: "";
      flex-grow: 10;
    }
    
    li {
      position: relative;
      display: block;
      box-sizing: border-box;
      border: 2px solid #888;
      border-radius: 2px;
      transition: border .25s ease-out;

      height: 20vh;
      flex-grow: 1;

      img {
        position: relative;
        // display: block;

        max-height: 100%;
        min-width: 100%;
        object-fit: cover;
        vertical-align: bottom;
        border-radius: 1px;
      }

      span {
        position: absolute;
        padding: .25em .25em .25em .4em;
        min-width: 1em;
        border-radius: 0 2px 0 .75em;
        background-color: rgba(136, 136, 136, .85);
        color: #fff;
        z-index: 1;
        top: -1px;
        right: -1px;
        text-align: center;
        font-weight: bold;
        transition: all .25s ease-out;
        text-shadow: 1px 1px 0 rgba(0, 0, 0, .25);

        &.none {
          opacity: .33;
          text-shadow: none;
        }
      }
    
      &:hover, &.selected {
        border-color: $colour_red;
        transition: none;

        span {
          background-color: $colour_red;
          text-shadow: none;
          opacity: 1;
          transition: none;
        }
      }
    }
  }

  .current-matches {
    .left {
      position: -webkit-sticky;
      position: sticky;
      align-self: flex-start;
      top: 64px;

      canvas {
        max-width: calc(100% - 1em);
        max-height: calc(100vh - 1em);
        margin: 1em auto 0 auto;
        display: block;
        position: relative;
      }
    }

    @media (min-width: 720px) {
      display: flex;
      gap: 1em;
      position: relative;

      .left {
        width: calc(33.33% - 1em);

        canvas {
          border-radius: 2px;
        }
      }
      .right {
        width: 66.66%;
      }
    }

    @media (max-width: 719px) {
      .left {
        height: 25vh;
        width: calc(100% + 2em);
        z-index: 1;
        transform: translateX(-1em);
        background: #666;
        box-shadow: 0 0 1em rgba(0,0,0,.2);

        canvas {
          max-height: 100%;
        }
      }
    }
  }

  .explanation {
    list-style: none;
    counter-reset: item;
    padding: 0;
    
    li {
      counter-increment: item;
      margin-bottom: 5px;

      &:before {
        margin-right: 1em;
        content: counter(item);
        background: $colour_red;
        border-radius: 100%;
        color: var(--colorNumber);
        font-weight: bold;
        width: 1.2em;
        text-align: center;
        display: inline-block;
        padding: .25em;
      }
    }

    .image {
      display: inline-block;
      position: relative;
      width: 1.5em;
      height: 1.5em;
      background: url("/img/icons/camera.svg") no-repeat bottom center;
      background-size: 80% auto;
      margin-right: .5em;

      &.wand {
        background-image: url("/img/icons/wand.svg");
        background-size: 100% auto;
      }

      &.cards {
        background-image: url("/cards.svg");
        background-size: auto 100%;
      }
    }
  }

  .tips {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;

    li {
      position: relative;

      .image {
        position: relative;
        display: block;
        width: calc(50vw - 2em);
        max-width: 160px;
        padding-bottom: 75%;
        background: url("/img/matches/examples/ok1.png") center center;
        background-size: 100%;
        border-radius: 2px;
        box-shadow: 0 0 0 2px $color_green;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -6px;
        right: -6px;
        width: 32px;
        height: 32px;
        border-radius: 100%;
        background: var(--colorNumber) url("/img/icons/ok.svg") no-repeat center center;
        background-size: 100%;
        z-index: 1;
        box-shadow: 0 0 0 2px var(--colorNumber);
      }

      &.ng {
        &:before {
          background-image: url("/img/icons/ng.svg");
        }

        .image {
          box-shadow: 0 0 0 2px $colour_red;
        }
      }

      &.img1 .image {
        @media only screen and (-moz-min-device-pixel-ratio: 2),
          only screen and (-o-min-device-pixel-ratio: 2/1),
          only screen and (-webkit-min-device-pixel-ratio: 2),
          only screen and (min-device-pixel-ratio: 2) {
          background-image: url("/img/matches/examples/ok1@2x.png");
        }
        @media only screen and (-moz-min-device-pixel-ratio: 3),
          only screen and (-o-min-device-pixel-ratio: 3/1),
          only screen and (-webkit-min-device-pixel-ratio: 3),
          only screen and (min-device-pixel-ratio: 3) {
          background-image: url("/img/matches/examples/ok1@3x.png");
        }
      }

      &.img2 .image {
        background-image: url("/img/matches/examples/ok2.png");
        @media only screen and (-moz-min-device-pixel-ratio: 2),
          only screen and (-o-min-device-pixel-ratio: 2/1),
          only screen and (-webkit-min-device-pixel-ratio: 2),
          only screen and (min-device-pixel-ratio: 2) {
          background-image: url("/img/matches/examples/ok2@2x.png");
        }
        @media only screen and (-moz-min-device-pixel-ratio: 3),
          only screen and (-o-min-device-pixel-ratio: 3/1),
          only screen and (-webkit-min-device-pixel-ratio: 3),
          only screen and (min-device-pixel-ratio: 3) {
          background-image: url("/img/matches/examples/ok2@3x.png");
        }
      }

      &.img3 .image {
        background-image: url("/img/matches/examples/ng1.png");
        @media only screen and (-moz-min-device-pixel-ratio: 2),
          only screen and (-o-min-device-pixel-ratio: 2/1),
          only screen and (-webkit-min-device-pixel-ratio: 2),
          only screen and (min-device-pixel-ratio: 2) {
          background-image: url("/img/matches/examples/ng1@2x.png");
        }
        @media only screen and (-moz-min-device-pixel-ratio: 3),
          only screen and (-o-min-device-pixel-ratio: 3/1),
          only screen and (-webkit-min-device-pixel-ratio: 3),
          only screen and (min-device-pixel-ratio: 3) {
          background-image: url("/img/matches/examples/ng1@3x.png");
        }
      }

      &.img4 .image {
        background-image: url("/img/matches/examples/ng2.png");
        @media only screen and (-moz-min-device-pixel-ratio: 2),
          only screen and (-o-min-device-pixel-ratio: 2/1),
          only screen and (-webkit-min-device-pixel-ratio: 2),
          only screen and (min-device-pixel-ratio: 2) {
          background-image: url("/img/matches/examples/ng2@2x.png");
        }
        @media only screen and (-moz-min-device-pixel-ratio: 3),
          only screen and (-o-min-device-pixel-ratio: 3/1),
          only screen and (-webkit-min-device-pixel-ratio: 3),
          only screen and (min-device-pixel-ratio: 3) {
          background-image: url("/img/matches/examples/ng2@3x.png");
        }
      }

      &.img5 .image {
        background-image: url("/img/matches/examples/ng3.png");
        @media only screen and (-moz-min-device-pixel-ratio: 2),
          only screen and (-o-min-device-pixel-ratio: 2/1),
          only screen and (-webkit-min-device-pixel-ratio: 2),
          only screen and (min-device-pixel-ratio: 2) {
          background-image: url("/img/matches/examples/ng3@2x.png");
        }
        @media only screen and (-moz-min-device-pixel-ratio: 3),
          only screen and (-o-min-device-pixel-ratio: 3/1),
          only screen and (-webkit-min-device-pixel-ratio: 3),
          only screen and (min-device-pixel-ratio: 3) {
          background-image: url("/img/matches/examples/ng3@3x.png");
        }
      }

      span {
        display: block;
        margin-top: .5em;
        text-align: center;
      }
    }
  }

  .compatibility {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 2em;

    li {
      position: relative;
      display: flex;
      align-items: center;

      .image {
        display: block;
        position: relative;
        width: 46px;
        height: 64px;
        background: url("/no_image.png") no-repeat center center;
        background-size: 100%;
        border-radius: 3px;
        margin-right: .5em;
        transform: rotate(5deg);
      }

      .text {
        position: relative;
        display: block;

        span {
          position: relative;
          display: block;

          &.language {
            &:before {
              display: inline-block;
              content: "";
              width: 1em;
              height: .75em;
              background: no-repeat bottom left;
              background-size: 100% auto;
              margin-right: .25em;
              box-shadow: 0 0 0 .5px rgba(0,0,0,.5);
              border-radius: 1px;
            }
            
            font-weight: bold;
            text-align: center;
          }
        }
      }

      &.en99 {
        .image {
          background-image: url("/img/matches/en_99.png");
          @media only screen and (-moz-min-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (min-device-pixel-ratio: 2) {
            background-image: url("/img/matches/en_99@2x.png");
          }
          @media only screen and (-moz-min-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (min-device-pixel-ratio: 3) {
            background-image: url("/img/matches/en_99@3x.png");
          }
        }
        .text span.language:before {
          background-image: url("/img/flags/en.svg");
        }
      }

      &.ja96 {
        .image {
          background-image: url("/img/matches/ja_96.png");
          @media only screen and (-moz-min-device-pixel-ratio: 2),
            only screen and (-o-min-device-pixel-ratio: 2/1),
            only screen and (-webkit-min-device-pixel-ratio: 2),
            only screen and (min-device-pixel-ratio: 2) {
            background-image: url("/img/matches/ja_96@2x.png");
          }
          @media only screen and (-moz-min-device-pixel-ratio: 3),
            only screen and (-o-min-device-pixel-ratio: 3/1),
            only screen and (-webkit-min-device-pixel-ratio: 3),
            only screen and (min-device-pixel-ratio: 3) {
            background-image: url("/img/matches/ja_96@3x.png");
          }
        }
        .text span.language:before {
          background-image: url("/img/flags/jp.svg");
        }
      }
    }
  }
}