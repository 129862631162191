@import 'mixins.scss';

$buttonBorder: 2px;

@mixin appDark {
  --bgBody: rgb(13, 17, 23);
  --colorText: rgb(201, 209, 217);
  --colorTextLight: #888;
  --colorTextExtraLight: rgb(70, 70, 70);

  --bgTopNav: rgba(22, 27, 34, .95);
	--mobileBackgroundGradient: linear-gradient(to bottom, rgba(13, 17, 23,.95), rgba(13, 17, 23,.7) 50%, rgba(13, 17, 23,0));
	--mobileSearchBorderColor: rgba(255,255,255,.3);
	--desktopSearchBackground: rgba(255,255,255,.15);
	--searchIcon: url("/search_small_dark.svg");
	--searchIconFocus: url("/search_small.svg");

  --bgFilterMenu: rgba(22, 27, 34);
  --bgFilterMenuSelectAll: rgba(255,255,255,.05);

  --borderHr: rgb(48, 54, 61);
  --colorFooter: rgb(79, 88, 97);

  --bgFormEls: rgb(10, 12, 16);
  --borderFormEls: rgb(105, 117, 128);
  --colorButtonHoverText: rgb(22, 27, 34);
  --bgButtonContainer: transparent;
  --buttonCountBackground: transparent;

  --bgPaginationSelected: transparent;

  --colorButtonText: #fff;
}
:root {
  --bgBody: #fff;
  --colorText: #000;
  --colorTextLight: #666;
  --colorTextExtraLight: rgb(190, 190, 190);

  --bgTopNav: rgba(255,255,255,.95);
	--mobileBackgroundGradient: linear-gradient(to bottom, rgba(255,255,255,.95), rgba(255,255,255,.7) 50%, rgba(255,255,255,0));
	--mobileSearchBorderColor: rgba(0,0,0,.3);
	--desktopSearchBackground: rgba(0,0,0,.1);
	--searchIcon: url("/search_small.svg");
	--searchIconFocus: url("/search_small_dark.svg");

  --bgFilterMenu: rgba(255,255,255);
  --bgFilterMenuSelectAll: rgba(0,0,0,.05);

  --borderHr: #ddd;
  --colorFooter: #bbb;

  --bgFormEls: white;
  --borderFormEls: #bbb;
  --colorButtonHoverText: #fff;
  --bgButtonContainer: #fff;
  --buttonCountBackground: rgba(255,255,255,.1);

  --bgPaginationSelected: #ddd;
}

body.dark {
  @include appDark;
}

@media (prefers-color-scheme: dark) {
  body:not(.light) {
    :root {
      @include appDark;
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap&subset=latin');

body {
  font-family: "Montserrat", "M PLUS 1p", "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
  background: var(--bgBody);
  color: var(--colorText);
  margin: 0;

  transition: all .25s ease-out;

  font-size: 11pt;
  @media (max-width: 460px) {
    font-size: 3.5vw;
  }
}

@media (max-width: 460px) {
  h1 {
    font-size: 6vw;
  }
  h2 {
    font-size: 4vw;
  }
}

.App {
  max-width: 1440px;
  margin: 0 auto !important;
}

a {
  color: $colour_red;

  &.helper {
    text-decoration: none;
    border-bottom: 1px dotted $colour_red;
  }
}

.lang-ja .tabs a {
  padding: .5em 1em;
}

.tabs {
  margin: 1em auto;

  &:not(.lessthan5) {
    > a, > div {
      @media (max-width: 599px) {
        .label-long, .label-help {
          display: none;
        }
      }
      @media (min-width: 600px) {
        .label-short {
          display: none;
        }
      }
    }
  }
  &.lessthan5 {
    > a, > div {
      @media (max-width: 599px) {
        .label-help {
          display: none;
        }
      }
      .label-short {
        display: none;
      }
    }
  }

  > a, > div {
    position: relative;
    display: inline-block;
    border-bottom: 2px solid transparent;
    padding: .5em .25em;
    text-decoration: none;

    @media (max-width: 399px) {
      &.inactive {
        display: none;
      }
    }

    &.selected:not(.inactive) {
      border-color: $colour_red;
    }
    &:not(.selected) {
      color: inherit;
    }

    .tip {
      display: block;
      text-align: center;
      color: $color_medgrey;
      margin-bottom: .25em;
      font-size: .8em;
    }

    span {
      display: block;
      text-align: center;
      margin: 0 auto;
    }

    .label-help {
      font-size: .8em;
      margin-top: .25em;
    }

    &.inactive {
      color: $color_lightgrey;
      pointer-events: none;
      cursor: default;
    }
  }

  @media (max-width: 749px) {
    display: flex;
    font-size: .8em;
    justify-content: space-evenly;
    align-items: flex-end;
    align-content: stretch;
  }
  @media (min-width: 750px) {
    text-align: center;
    a {
      display: inline-block;
      padding: .5em 1em;
      margin: 0 .5em;
    }
  }
}

p {
  position: relative;
}

label {
  position: relative;
  display: block;
  margin-bottom: .25em;
}

select {
  -webkit-appearance: none;
  appearance: none;
}
select::-ms-expand {
  display: none;
}
select:-moz-focusring {
  color: transparent !important;
  // text-shadow: 0 0 0 var(--white);
}
select {
  background: var(--bgFormEls);
  border: 1px solid var(--borderFormEls);
  color: var(--colorText);
  padding: .25rem .5rem;
  display: block;
  font-size: .75em;

  background: url("/select_down_dark.svg") top 55% right .75rem;
  background-size: auto 25%;
  background-repeat: no-repeat;
}

.select > div > div:first-of-type {
  border-width: 2px;
  border-color: var(--borderFormEls);
  border-radius: .5rem;
  > div:first-child {
    padding-left: 1rem;
    > div {
      margin: 0;
    }
  }
}

button, input[type="submit"], input[type="text"], input[type="password"], input[type="email"], input[type="number"], input[type="date"], input[type="email"], textarea, .button {
  -webkit-appearance: none;
  appearance: none;
  border: $buttonBorder solid var(--borderFormEls);
  padding: .5rem 1rem;
  background: var(--bgFormEls);
  font-size: inherit;
  font-family: inherit;
  box-sizing: border-box;
  border-radius: .5rem;
  text-decoration: none;
  display: inline-block;
  color: inherit;

  &.full {
    width: 100%;
  }
  &.half, &.small {
    width: 50%;
  }

  &:disabled {
    opacity: .5;
    cursor: default;
  }
}

input[type="date"] {
  // because iOS is a dumb-dumb
  display: block;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  min-height: 1.2em;
}

textarea {
  width: 100%;
}

input[type="radio"] {
  margin-right: .5rem;
}

.no-decoration {
  text-decoration: none;
}

:not(.button) {
  // button hover effect should never apply to grouped buttons
  > button, > input[type="submit"], > .button {
    &:not(.container):not(.selected):not(:disabled):not(.no-effect):hover {
      background: transparent;
      color: $colour_red;
    }
  }
}

.small {
  font-size: .8em;
}
a.black {
  color: inherit;
}

.disabled {
  opacity: .25;
}

button, input[type="submit"], .button {
  border-color: $colour_red;
  background: $colour_red;
  color: var(--colorButtonHoverText);
  font-weight: bold;
  border-radius: 2rem;
  cursor: pointer;
  padding: .75em 1em;
  margin: 0;
  text-align: center;
  transition: opacity .15s linear, background-color .15s linear, color .15s linear;
  width: auto;
  white-space: nowrap;

  &:disabled {
    opacity: .5;
    cursor: default;
  }

  &.quantity {
    position: relative;
    background: transparent;
    color: $colour_red;
    border-radius: 1em;
    width: 1.5em;
    height: 1.5em;
    padding: 0;

    &.down:before, &.up:before {
      content: "";
      position: absolute;
      display: block;
      width: 60%;
      left: 20%;
      height: 3px;
      top: 50%;
      margin-top: -1.5px;
      border-radius: 2px;
      background: $colour_red;
    }

    &.up:after {
      content: "";
      position: absolute;
      display: block;
      width: 3px;
      height: 60%;
      top: 20%;
      left: 50%;
      margin-left: -1.5px;
      border-radius: 2px;
      background: $colour_red;
    }
  }

  &.small, &.tool, &.helper {
    padding: .25rem .75rem;
    width: auto;
  }
  &.large {
    padding: .75rem 1rem;
    width: 100%;
  }

  &.tool, &.helper, &.white {
    background: transparent;
    color: $colour_red;
    &:not(.container):not(.selected):not(:disabled):hover {
      background: $colour_red;
      color: var(--colorButtonHoverText);
    }
  }

  &.helper {
    position: absolute;
    top: 0;
    right: 0;
    padding: .1em .4em;
    line-height: 1em;
    z-index: 1;
    top: -.2em;
    font-size: .9rem;
  }

  &.remove {
    border-radius: .25em;
    padding: 0 .75em;
    position: relative;
    color: transparent !important;
    font-size: .75em;
    margin-right: .5em;
    background: transparent;

    &:before, &:after {
      content: "";
      display: block;
      position: absolute;
      width: 2px;
      height: 80%;
      top: 10%;
      left: 50%;
      margin-left: -1px;
      background: $colour_red;
      border-radius: 1px;
    }
    &:before {
      transform: rotate(45deg);
    }
    &:after {
      transform: rotate(-45deg);
    }

    &:hover {
      background: $colour_red !important;

      &:before, &:after {
        background-color: var(--bgBody);
      }
    }
  }

  &.container {
    padding: 0;
  }
}

.quantity-select {
  position: relative;
  display: inline-block;
  display: flex;
  padding: 0 .5em;
  align-items: center;

  span {
    position: relative;
    display: block;
    margin: 0 .75em;
    min-width: 4em;
    text-align: center;
  }
}

div.button {
  cursor: default;
}

.button-count {
  position: relative;
  display: inline-block;
  border: 1px solid rgba(0,0,0,.67);
  background: var(--buttonCountBackground);
  box-sizing: border-box;
  height: 2em;
  padding: 0 .75em;
  border-radius: .25em;
  margin-left: .75em;
  line-height: 2em;
  pointer-events: none;

  &:before {
    position: absolute;
    top: 50%;
    margin-top: -6px;
    left: -12px;
    content: "";
    display: block;
    border: 6px solid transparent;
    border-right-color: rgba(0,0,0,.67);
  }
}

.button > button, .button > span {
  border: none;
  background: var(--bgButtonContainer);
  margin: 0;
  display: inline-block;
  cursor: default;
  &:not(span) {
    color: inherit;
    cursor: pointer;
  }
  &.selected, &:hover {
    background: $colour_red;
    color: var(--colorButtonHoverText);
  }
  width: auto;
  text-align: center;
  border-radius: 0;
  &:first-child {
    border-radius: 2em 0 0 2em;
  }
  &:not(:first-child) {
    box-shadow: -1px 0 0 0 #EE3942, inset 1px 0 0 0 #EE3942;
  }
  &:last-child {
    box-shadow: -1px 0 0 0 #EE3942, inset 1px 0 0 0 #EE3942;
    border-radius: 0 2em 2em 0;
  }
  padding: .25rem 1rem;
  &.w33, &.w50, &.w66, &.w75 {
    padding: .25rem 0;
  }
  &.w33 {
    width: 33.33%;
  }
  &.w50 {
    width: 50%;
  }
  &.w66 {
    width: 66.66%;
  }
  &.w75 {
    width: 75%;
  }
}

.selectable {
  cursor: pointer;

  &.selected {
    .select-indicator {
      background-color: $colour_red;
    }
  }

  &:hover {
    .select-indicator {
      border-style: solid;
    }
  }
}

.flex {
  display: flex !important;
  gap: 1em;
  align-items: center;
}

.select-indicator {
  display: inline-block;
  width: 1em;
  height: 1em;
  border: 2px dotted $colour_red;
  border-radius: 1em;
}

form {
  span.helper {
    display: block;
    font-size: .8em;
    margin: 1em 0;
    background: #fff;
    padding: 1em;
    border-radius: .5rem;
  }

  p > span:not(.helper) {
    margin-right: .5em;
  }

  p:not(.helper-on) span.helper {
    display: none;
  }
}

div.single-line {
  display: flex;
  gap: 1rem;

  > * {
    flex-grow: 1;
  }
}

form.single-line {
  > p, > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;

    > input,
      > label:last-child,
      > label:last-child input {
      flex-grow: 1;
      margin: 0 0 0 1em;
    }

    > input,
      > label:last-child {
      max-width: 60%;
      text-align: right;
    }

    &:not(.noflex) {
      > label:last-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    > div {
      width: 60%;
      display: flex;
      > * {
        width: 100%;
      }
    }
  }
  > div {
    margin: 1em 0;
  }
}

table {
  border-collapse: collapse;
  
  tr:not(:last-of-type), thead {
    border-bottom: 1px solid var(--borderHr);
  }
  td {
    vertical-align: top;
    padding: 1em .5em;
  }
  tr td:first-of-type, thead {
    color: var(--colorTextLight);
    font-weight: bold;
  }
}

.pagination {
  text-align: center;
  margin: 1em 0;
  .button, button {
    margin: 0 .5em;
    display: inline-block;
    width: auto;
    &.selected {
      background: var(--bgPaginationSelected);
      color: inherit;
      border-color: $color_lightgrey;
      cursor: default;
      pointer-events: none;
    }
  }
}

hr {
  border: 0;
  border-top: 1px solid var(--borderHr);
  margin: 2em 0;
}

.nav-menu {
  position: fixed;
  pointer-events: none;
  opacity: 0;
  z-index: 91;
  transition: opacity .15s ease-out;
  top: 0;

  &.on {
    opacity: 1;
    pointer-events: all;

    .filter-container {
      transform: translateX(0);
    }
  }

  width: 100%;
  box-sizing: border-box;
  height: 100%;
  padding: 0 0 0 28vw;
  background-color: rgba(0,0,0,.666);


  @media (max-width: 749px) {
    font-size: 3.25vw;
  }

  .close-filter-menu {
    position: fixed;
    width: 28vw;
    height: 100%;
    top: 0;
    left: 0;
  }

  .filter-container {
    position: relative;
    width: 100%;
    height: 100%;
    background: var(--bgFilterMenu);
    box-shadow: 0 .25rem 1rem rgba(0,0,0,.15);
    overflow: hidden;
    transition: transform .25s ease-out;

    // slide-in animation
    transform: translateX(33%);

    .filter-inner {
      position: absolute;
      height: 100%;
      display: flex;
      transition: transform .4s ease-out;
      transform: translateX(0%);
      padding-top: $header_height_mobile;
      box-sizing: border-box;

      padding-bottom: calc(5em + $buttonBorder * 2); // bottom padding in consideration of reset/apply buttons

      .filter-menu {
        overflow: hidden;
      }
    }
    
    // hide overflow of non-visible menus
    &.subview-show-0 .filter-menu:nth-child(1),
      &.subview-show-1 .filter-menu:nth-child(2),
      &.subview-show-2 .filter-menu:nth-child(3),
      &.subview-show-3 .filter-menu:nth-child(4) {
      overflow-y: auto;
    }
  
    // maximum of 5 menus
    // if this should increase in the future, adjust the widths and translateXs
    // e.g. 6 -> .filter-inner { width: 600%; } .filter-menu { width: calc(100%/6); } etc

    .filter-inner {
      width: 500%;
    }
    .filter-menu {
      width: 20%;
    }

    &.subview-show-4 .filter-inner {
      transform: translateX(-80%);
    }
    &.subview-show-3 .filter-inner {
      transform: translateX(-60%);
    }
    &.subview-show-2 .filter-inner {
      transform: translateX(-40%);
    }
    &.subview-show-1 .filter-inner {
      transform: translateX(-20%);
    }

    &.subview-show-0 {
      .next {
        flex-grow: 0;
      }

      span.title {
        white-space: nowrap;
      }
    }

    &:not(.subview-show-0) {
      .buttons .reset {
        opacity: 0;
        pointer-events: none;
      }
    }

    .buttons {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 0;
      padding: 1em;
      display: flex;
      gap: 1em;
      box-sizing: border-box;
      background: var(--bgFilterMenu);

      button {
        display: block;
        flex-grow: 1;
        padding-left: 0;
        padding-right: 0;
      }
    }
  }

  .filter-menu {
    position: relative;
    border-radius: 0 0 .5rem .5rem;
    list-style-type: none;
    padding: 0;
    margin-top: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-content: flex-start;
    user-select: none;

    ul {
      padding: 0;
    }

    @media (max-width: 539px) {
      height: 100%;
    }

    li {
      position: relative;
      display: flex;
      justify-content: flex-start space-between;
      width: 100%;
      border-top: 1px solid var(--borderHr);
      box-sizing: border-box;

      &:not(input) {
        cursor: default;
      }

      > a, > span {
        display: block;
        padding: 1em;
        flex-grow: 1;
      }
      @media (min-width: 540px) {
        &:first-child {
          border-top: none;
        }
      }

      &.selectall {
        background-color: var(--bgFilterMenuSelectAll);
      }

      > a, > span {
        &.option, &.choice {
          padding-left: 0;
          flex-grow: 0;
        }
      }

      .checkbox {
        position: relative;
        display: block;
        padding: 0;
        width: 0;
        height: 0;
        flex-grow: 0;
        flex-shrink: 0;
        border: .5em transparent solid;
        transition: border-color .1s linear;
        margin-right: 0;
      }

      &.selected {
        font-weight: bold;

        .checkbox {
          border-top-color: $colour_red;
          border-left-color: $colour_red;
        }
        &.some .checkbox {
          border-top-color: rgba($colour_red, .33);
          border-left-color: rgba($colour_red, .33);
        }
      }

      &.choices-none > span {
        &.forward, &.next {
          color: var(--colorTextExtraLight);
        }
      }

      &:not(.choices-none) > span,
        > span.choice {
        &.bold {
          font-weight: bold;
        }

        display: inline-block;
      }

      &.header {
        background-color: var(--borderHr);
        text-align: center;

        .back {
          flex-grow: 0;
          width: 1em;
        }

        > span.title {
          flex-grow: 1;
          padding-left: 0;
          padding-right: 3em;
          white-space: auto;
        }
      }

      &.input-menu {
        :not(input) {
          flex-grow: 0;
        }
      }

      input {
        border: none;
        text-align: right;
        border-radius: 0;
        flex-grow: 1;

        &::-webkit-outer-spin-button, 
          &::-webkit-inner-spin-button {
          margin-left: 1em;
        }
      }
      
      > span {
        position: relative;
        display: block;

        span {
          display: inline-block;

          &:not(:first-of-type) {
            margin-left: 1em;
          }
        }

        .truncate {
          font-weight: normal;
        }

        &:not(:last-child).forward {
          padding-right: 0;
        }

        &.forward {
          text-align: right;
        }

        &.next {
          margin-left: auto;
          text-align: right;
          flex-shrink: 0;
        }
      }
    }
  }
}

.nav-container {
  @media (max-width: 749px) {
    &.nav-desktop {
      display: none;
    }
  }
  @media (min-width: 750px) {
    &.nav-mobile {
      display: none;
    }
  }
}

.nav-bottom {
  position: fixed;
  z-index: 80;
  left: 0;
  width: 100%;
  font-size: 2.5vw;

  bottom: 0;
  padding-bottom: env(safe-area-inset-bottom);

	display: flex;
	justify-content: center;

	ul {
		position: relative;
		display: inline-flex;
		margin: 0 2vw 2vw;
		padding: 0;

		justify-content: flex-start;
		list-style-type: none;

		border-radius: 2.5vh;
		background: var(--bgTopNav);
		box-shadow: 0 2px 15px rgba(0,0,0,.2), inset 0 0 0 1px rgba(255,255,255,.2);

		li {
			flex-grow: 1;
	
			&.categories a:before {
				background-image: url("/cards.svg");
				background-size: auto 80%;
			}
			&.categories-all a:before {
				background-image: url("/collection-old.svg");
				background-size: 40% auto;
			}
			&.collection a:before {
				background-image: url("/collection.svg");
				background-size: 45% auto;
			}
			&.sell a:before {
				background-image: url("/sell.svg");
				background-size: 45% auto;
			}
			&.basket a:before {
				background-image: url("/bag.svg");
				background-size: auto 80%;
			}
			&.account a:before {
				background-image: url("/account.svg");
				background-size: auto 70%;
			}

			a {
				&:before {
					content: "";
					position: relative;
					display: block;
					width: 100%;
					padding-bottom: 50%;
					margin-bottom: 1vw;
				
					background: center center no-repeat;
					cursor: pointer;
				}

				position: relative;
				min-height: calc($footer_height_mobile - 2em);
				min-width: 17vw;
				padding: 2vw 0;
				text-decoration: none;
				cursor: pointer;
				text-align: center;

				display: flex;
				align-items: center;
				flex-direction: column;
				justify-content: center;
			}

			&:first-child a {
				padding-left: 3vw;
			}
			&:last-child a {
				padding-right: 3vw;
			}
		}
	}
}

.nav-mobile {
	&:after {
		content: "";
		position: fixed;
		display: block;
		width: 100%;
		// height: calc($header_height_mobile * 1.5);
		height: $header_height_mobile;
		top: 0;
		left: 0;
		z-index: 90;
		background: var(--mobileBackgroundGradient);
		pointer-events: none;
	}

  .nav-top {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: $header_height_mobile;
		z-index: 100;
		font-size: .75rem;

		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 1.5vw;

		&:before {
			content: "";
			position: absolute;
			display: block;
			width: 100%;
			height: 2px;
			top: 0;
			left: 0;
			background-color: $colour_red;
		}

		h1.logo, h1.logo a {
			position: relative;
			display: block;
			width: 28vw;
			height: $header_height_mobile;
      margin: 0;
		}

		h1.logo a {
			background: $colour_red url("/logo_white.svg") no-repeat center center;
			background-size: 75% auto;
			font-size: 0;
			color: transparent;
		}

		> *:not(h1, .search) {
			display: block;

			&:last-child {
				margin-right: 2vw;
			}
		}

		.button:not(.sell) {
			padding: calc(1.5vw - 2px) 2vw;
			font-size: .75rem;
			box-sizing: border-box;
			background-color: var(--bgBody);
		}

    .steps {
      position: relative;
      width: 100%;
      height: 100%;
      padding: 0 1em;
      box-sizing: border-box;
      display: flex;
      align-items: center;

      .outer {
        position: relative;
        width: 100%;
      }

      .indicator {
        position: relative;
        display: block;
        margin: .25em 0 .4em;
        width: 100%;
        height: .5em;
        border-radius: 1em;
        overflow: hidden;
        background-color: #E5E5E5;

        div {
          position: absolute;
          height: 100%;
          border-radius: 1em;
          background-color: #666;
          transition: width 1s ease-out;
          width: 100%;
        }
      }

      &:not(.complete) .indicator div {
        width: calc(100% - 1em);
      }

      .label {
        text-align: right;
        font-size: 2.5vw;
      }
    }

    button.filter {
      position: relative;
      width: 8vw;
      height: calc(1em + 3vw);
      font-size: 3.25vw;
      color: transparent;
      border-radius: 0;
      padding: 0;
      border: none;
      background: transparent;

      &:before, &:after {
        content: "";
        position: absolute;
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: url('/close.svg') center center no-repeat;
        background-size: 40% auto;
        transition: opacity .25s linear;
      }

      &:before {
        background-image: url('/filters.svg');
        background-size: contain;
        border-top: 1vw solid transparent;
        border-bottom: 1vw solid transparent;
        box-sizing: border-box;
      }

      &:after {
        opacity: 0;
      }
    }

    ul {
			padding: 0;
			list-style: none;
    }
  }

	.count {
		position: absolute;
		display: block;
		color: var(--bgBody);
		background: $colour_red;
		padding: .2em;
		border-radius: 2em;
		min-width: 1.25em;
		text-align: center;
		top: 37.5%;
		margin-left: .5em;
	}

	.search {
		// padding-right: 7.5vw;
		height: calc(1em + 3vw + 4px);
		font-size: 3.25vw;
		margin-left: calc(3vw - 2px);
		flex-grow: 1;
    box-shadow: inset 0 0 0 $buttonBorder var(--mobileSearchBorderColor);
	}
}

header.filterMenuDisplayed .nav-mobile .nav-top {
  button.filter {
    &:before {
      opacity: 0;
    }
    &:after {
      opacity: 1;
    }
  }
}

span.filter {
  background: $color_greyfont;
  color: #fff;

  &.grass { background-color: #8CB93A; }
  &.fire { background-color: #EA5E3A; }
  &.water { background-color: #37A3EB; }
  &.lightning { background-color: #F8C904; color: #000; }
  &.psychic { background-color: #A16BA9; }
  &.fighting { background-color: #C87E35; }
  &.darkness { background-color: #083636; }
  &.metal { background-color: #93A8A1; }
  &.dragon { background-color: #A5A02A; }
  &.fairy { background-color: #CF4382; }
  &.colorless { background-color: #fff; color: #000; }
}

nav {
	.search {
		position: relative;
		border: none;
		background: var(--bgTopNav);
		margin-right: 2px;
		border-radius: 2em;
		overflow: hidden;

		display: flex;
		align-items: center;
		// gap: 1vw;

    transition: all .25s linear;

		span.filter {
			display: inline-block;
			font-size: .75em;
			cursor: default;
			color: #fff;
			padding: .1em .5em;
			border-radius: .3em;
			background: rgba(0,0,0,.5);
			line-height: 1.4em;
		}

		input {
			position: relative;
			width: 100%;
			height: 100%;
			display: inline-block;
			border: none;
			background: transparent;
			box-sizing: border-box;
			border-radius: 0;
      padding: 1.5vw calc(3vw + 2px);

			outline: none;
		}

    button {
      width: 3em;
      height: 100%;
      border-radius: inherit;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding: 0;
      flex-grow: 0;
      flex-shrink: 0;
      border: 0;
      background-image: var(--searchIconFocus);
      background-repeat: no-repeat;
      background-position: center center;
      background-size: auto 50%;
    }

    &:not(.active) button {
      background-image: var(--searchIcon);
      background-color: transparent;
      opacity: .5;
      cursor: default;
    }
	}
}

header:not(.filterMenuDisplayed) nav .search {
  &.active {
    box-shadow: inset 0 0 0 2px $colour_red;
    background-color: #fff;
  }
}

header.filterMenuDisplayed nav .search {
  button {
    opacity: 0;
    pointer-events: none;
  }
}

.nav-desktop {
	position: fixed;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	background: var(--bgBody);

	.search {
		width: 20%;
		height: 2em;
		padding-right: 0;
		margin: auto 0;
		background-color: var(--desktopSearchBackground);

    &:before {
      background-position: right 1em center;
    }

    input {
      padding: 1.5vw 1.25em;
    }
	}
	
	.nav-top {
		position: relative;
		width: 100%;
		max-width: 1440px;
		margin: 0 auto;
		height: $header_height_desktop;

		display: flex;
		justify-content: flex-start;

		a, button {
			-webkit-tap-highlight-color: transparent;
		}

		h1.logo, h1.logo a {
			position: relative;
			display: block;
			margin: 0;
		}

		h1.logo a {
			background: $colour_red url("/logo_white.svg") no-repeat center center;
			background-size: 75% auto;
			font-size: 0;
			color: transparent;
		}

		@media (max-width:  1099px) {
			height: $header_height_desktop;
			font-size: 1.5vw;

			h1.logo, h1.logo a {
				width: 12vw;
				height: $header_height_desktop;
			}

			h1.logo, .search {
				margin-right: 2vw;
			}
		}
		@media (min-width:  1100px) {
			height: $header_height_desktop_max !important;
			font-size: .9rem;

			h1.logo, h1.logo a {
				width: 130px;
				height: $header_height_desktop_max;
			}

			h1.logo, .search {
				margin-right: 30px;
			}
		}

		.count {
			display: inline-block;
			color: var(--bgBody);
			background: $colour_red;
			padding: .2em;
			border-radius: 2em;
			min-width: 1.25em;
			text-align: center;
			margin-left: .5em;
		}
	
		ul {
			height: 100%;
			list-style-type: none;
			
      display: flex;
      justify-content: flex-start;
      flex-direction: row;

			margin: 0;
			padding: 0;

			&.right {
				margin-left: auto;
			}

			li.icon {
        position: relative;
        display: block;
	
				white-space: nowrap;
			}

			li.icon {
				&:not(.login) > a {
					background: center left no-repeat;
					border: 0;
					cursor: pointer;
				}

				&.home a {
					background-image: url("/home.svg");
					background-size: auto 35px;
				}

        > a, > span, > button {
          width: auto;
          display: block;
          background-position: .5rem center;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          text-decoration: none;
        }
			}

			li.icon.collection a {
				background-image: url("/collection.svg");
			}
			li.icon.categories-all a {
				background-image: url("/collection-old.svg");
			}
			li.icon.categories a {
				background-image: url("/cards.svg");
			}
			li.icon.basket a {
				background-image: url("/bag.svg");
			}
			li.icon.account a {
				background-image: url("/account.svg");
			}

			li.sell, li.login {
				display: flex;
				align-items: center;
  			justify-content: center;
			}

			li.sell a {
				border-radius: 8px;

				&.lowlight {
					border: none;
					font-weight: 300;
					background: transparent;
					color: $colour_red;
				}
			}

			@media (max-width:  1099px) {
				li.icon > a {
					padding: 0 3vw 0 4.5vw;
				}
				li.icon.collection a {
					background-size: auto 2.5vw;
				}
				li.icon.categories a {
					background-size: auto 3vw;
					padding-left: 3.5vw !important;
				}
				li.icon.categories-all a {
					background-size: auto 2.5vw;
					padding-left: 3.5vw !important;
				}
				li.icon.basket a {
					background-size: auto 3vw;
					padding-left: 3.75vw !important;
				}
				li.icon.account a {
					background-size: auto 2.5vw;
					padding-left: 3.5vw !important;
				}
				li.sell {
					padding: 0 3vw 0 0;
				}
      }

			@media (min-width:  1100px) {
				li.icon > a {
					padding: 0 30px 0 45px;
				}
				li.icon.collection a {
					background-size: auto 26px;
				}
				li.icon.categories a {
					background-size: auto 33px;
					padding-left: 38px !important;
				}
				li.icon.categories-all a {
					background-size: auto 28px;
          padding-left: 42px !important;
				}
				li.icon.basket a {
					background-size: auto 30px;
					padding-left: 38px !important;
				}
				li.icon.account a {
					background-size: auto 24px;
					padding-left: 33px !important;
				}
				li.sell {
					padding: 0 30px 0 0;
				}
      }
    }
  }
}

// nav highlighting
.nav-mobile.nav-container.sell li.sell,
  .nav-mobile.nav-container.categories li.categories-all,
  .nav-mobile.nav-container.u.myProfile li.collection,
  .nav-mobile.nav-container.account li.account,
	.nav-desktop.nav-container.sell li.sell,
  .nav-desktop.nav-container.categories li.categories-all,
  .nav-desktop.nav-container.u.myProfile li.collection,
  .nav-desktop.nav-container.account li.account {
  a {
    filter: grayscale(100%);
  }
}
body:not(.dark) .nav-mobile.nav-container.sell li.sell,
	body:not(.dark) .nav-mobile.nav-container.categories li.categories-all,
	body:not(.dark) .nav-mobile.nav-container.u.myProfile li.collection,
	body:not(.dark) .nav-mobile.nav-container.account li.account,
	body:not(.dark) .nav-desktop.nav-container.sell li.sell,
	body:not(.dark) .nav-desktop.nav-container.categories li.categories-all,
	body:not(.dark) .nav-desktop.nav-container.u.myProfile li.collection,
	body:not(.dark) .nav-desktop.nav-container.account li.account {
  a {
    opacity: .75;
  }
}

.App {
  .errors {
    margin-top: 0;
    padding: 0;
    background: $colour_red;
    color: #fff;
    list-style: none;
    border-radius: 1.5rem;
    box-shadow: 0 0 0 $buttonBorder #fff;

    li {
      padding: .75rem 1rem;

      &:not(:first-child) {
        border-top: 2px solid #fff;
      }

      .problem {
        &:before {
          content: "• ";
        }
        text-align: left;
      }

      button {
        margin-left: .5rem;

        &.white {
          border-color: #fff;
          color: #fff;
        }
      }
    }
  }

  > main {
    padding: 1rem;
		@media (min-width: 750px) and (max-width:  1099px) {
			margin-top: $header_height_desktop;
		}
		@media (min-width:  1100px) {
			margin-top: $header_height_desktop_max;
		}

    @media (min-width: 1460px) {
      padding: 1rem 0;
    }
  }

  @media (max-width: 749px) {
    > main > :first-child {
      &:is(h1) {
        margin-top: 0;
      }
    }
  }
}

body.minimal_navigation .App {
  @media (max-width: 749px) {
    min-height: --moz-available;
    min-height: --webkit-fill-available;
    min-height: stretch;
    display: flex;

    main {
      padding-top: $header_height_mobile;
      flex-grow: 1;
      flex-flow: column;
    }
  }
}
body:not(.minimal_navigation) .App > main {
  @media (max-width: 749px) {
    margin-top: $header_height_mobile;
  }
}

body {
	&:not(.minimal_navigation) {
    padding-bottom: calc(2 * env(safe-area-inset-bottom));

    @media (max-width: 749px) {
      margin-bottom: $footer_height_mobile;
    }
  }

	&:before {
		content: "";
		position: fixed;
		width: 100%;
		height: 2px;
		top: 0;
		left: 0;
		background: $colour_red;
		z-index: 101;
	}
  
  @media (max-width: 749px) {
    main {
      // min-height: calc(100vh - $header_height_mobile);
      // min-height: calc(var(----minPageHeight) - $header_height_mobile);
      box-sizing: border-box;
      display: flex;
      flex-flow: column;

      .narrow, .semi-narrow, .slim, .container {
        flex-grow: 1;
        display: flex;
        flex-flow: column;
      }

      .full-height {
        flex-grow: 1;
        display: flex;
        flex-flow: column;
      }

      .bottom-element {
        margin-top: auto;

        :last-child {
          margin-bottom: 0;
        }
      }

      .bottom-element + .bottom-element { margin-top: 0; }
    }
  }
}

.narrow {
  max-width: 480px;
  margin: 0 auto;
}

.semi-narrow {
  max-width: 750px;
  margin: 0 auto;
}

@media (min-width: 750px) {
  .slim {
    max-width: 950px;
    margin: 0 auto;
  }
}

.hide-mobile {
  @media (max-width: 749px) {
    display: none !important;
  }
}
.hide-desktop {
  @media (min-width: 750px) {
    display: none !important;
  }
}

ul.filters {
  list-style-type: none;
  padding: 0;
  li {
    display: inline-block;
    margin-right: 1em;
  }
}

footer {
  border-top: 1px solid var(--borderHr);
  padding: 1rem;
  p {
    font-size: .75rem;
    color: var(--colorFooter);
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      display: inline-block;
      margin-right: 1em;
    }
  }
}

body.minimal_navigation {
  @media (max-width: 749px) {
    footer {
      display: none;
    }
  }
}