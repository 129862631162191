@import 'mixins.scss';

@mixin modalDark {
    --bgModal: rgb(22, 27, 34);
    --borderModal: rgb(105, 117, 128);
}
:root {
    --bgModal: #fff;
    --borderModal: transparent;
}
body.dark {
    @include modalDark;
}
@media (prefers-color-scheme: dark) {
    body:not(.light) {
        :root {
            @include modalDark;
        }
    }
}

.modal-container {
    font-size: 1em;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,.9);
    background: linear-gradient(to bottom right, rgba(0,0,0,.7), rgba(0,0,0,.9));
    z-index: 200;

    transition: opacity .2s ease-in;

    &.off {
        opacity: 0;
        pointer-events: none;

        transition: transform .5s ease-out, opacity .5s ease-out;

        .modal {
            transform: scale(.9) translateY(2rem);
            opacity: 0;
            transition: none;
        }
    }

    display: flex;
    align-items: center; /*for vertically center*/
    // justify-content:center; /*for horizontally center*/

    .modal {
        position: relative;
        width: 100%;
        @media (max-width: 599px) {
            max-height: 95vh;
        }
        @media (min-width: 600px) {
            max-height: 85vh;
        }
        overflow-y: auto;
        max-width: calc(570px - 2rem);

        transition: transform .25s ease-out, opacity .25s ease-out;

        background: var(--bgModal);
        box-shadow: 0 0 0 2px var(--borderModal);
        border-radius: 2rem;
        @media (max-width: 600px) {
            margin: 1rem;
        }
        margin: 0 auto;
        padding: 1rem;

        > p {
            text-align: center;
        }

        h1 {
            text-align: center;
            margin: 0 0 1rem;
        }

        > div:last-child, > div:last-child > * {
            margin-bottom: 0;
        }

        button.share {
            &.share-facebook {
                color: #4267B2;
                border-color: #4267B2;
                &:hover {
                    background: #4267B2;
                }
            }
            &.share-hatena {
                color: #00a5de;
                border-color: #00a5de;
                &:hover {
                    background: #00a5de;
                }
            }
            &.share-line {
                color: #00B900;
                border-color: #00B900;
                &:hover {
                    background: #00B900;
                }
            }
            &.share-reddit {
                color: #FF4500;
                border-color: #FF4500;
                &:hover {
                    background: #FF4500;
                }
            }
            &.share-whatsapp {
                color: #4AC959;
                border-color: #4AC959;
                &:hover {
                    background: #4AC959;
                }
            }
            &.share-twitter {
                color: #1DA1F2;
                border-color: #1DA1F2;
                &:hover {
                    background: #1DA1F2;
                }
            }
            &.share-telegram {
                color: #0088cc;
                border-color: #0088cc;
                &:hover {
                    background: #0088cc;
                }
            }

            &:hover {
                color: #fff;
            }

            display: inline-block;
            margin: .5em;
        }

        .share-buttons {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
        }

        .upload-preview {
            img {
                max-width: 100%;
                max-height: 20vh;
                border: 2px solid $color_greyfont;
                border-radius: .25em;
                padding: .5em;
                background: #fff;
            }
            &:not(.show) {
                display: none;
            }
        }

        ul.remove-card-list {
            li {
                margin: 1em auto;
                span:not(:last-of-type) {
                    &:after {
                        // content: "•";
                        // margin-right: .5em;
                        // margin-left: .5em;
                    }
                }
                span {
                    margin-right: .5em;

                    &.buy-price, &.buy-date {
                        color: $color_gold;
                        font-size: .75em;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                    &.sell-price, &.sell-date {
                        color: $color_disabled;
                        font-size: .75em;
                        text-transform: uppercase;
                        font-weight: bold;
                    }
                }
                button {
                    margin: .25em 0 .25em 1em;
                }
            }
        }

        .basket {
            .success {
                padding: .5em;
                border: 2px solid $color_green;
                border-radius: .5em;
                color: $color_green;
            }
        }
    }
}
