.collections {
  .collection-nav {
    display: flex;
    justify-content: space-between;
    
    form {
      display: inline-block;
    }
  }

  .search-header {
    font-weight: 300;
  }
}
