@use 'sass:math';
@import 'mixins.scss';

@mixin cardSingleDark {
  --color-tax: #{$color_greyfont};
}

:root {
  --color-tax: #{$color_medgrey};
}
body.dark {
  @include cardSingleDark;
}
@media (prefers-color-scheme: dark) {
  body:not(.light) {
    :root {
        @include cardSingleDark;
    }
  }
}

.rating.score {
  width: calc(5em);
  overflow: hidden;
  color: transparent;
  display: inline-block;
  position: relative;
  height: 1em;
  pointer-events: none;

  &:before, &:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    display: block;
    content: "";
    background-image: url("/star-1px-right-gap.svg");
    background-size: 1em 100%;
    min-width: 1em;
  }

  &:after {
    width: 5em;
    opacity: .25;
  }

  @for $i from 1 through 9 {
    &.l#{$i}:before {
      width: calc(#{math.div($i, 2) * 0.9349112426}em + #{floor(math.div($i, 2))}px);
      max-width: 100%;
    }
  }
  &.l10:before {
    width: 100%;
  }
}

.sale-notice {
  display: inline-block;
  color: $colour_red;
  border: 2px solid $colour_red;
  width: auto;
  font-size: 1rem;
  font-weight: bold;
  padding: .4em .8em;
  text-transform: uppercase;
}

.sale-price {
  display: inline-block;
  margin-left: 1em;
  font-size: 1.2em;
  // transform: translateY(4px);
  padding: 0 0 0 .5em;
  
  .price {
    font-weight: bold;
    font-size: 1.4em;
  }
  .currency {
    font-weight: bold;
  }
  .tax {
    margin-left: .5em;
    color: var(--color-tax);
    display: inline-block;
    font-size: .8em;
  }
}