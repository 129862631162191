@import 'mixins.scss';

main.u {
  .user:not(.clickable) {
    pointer-events: none;

    a {
      pointer-events: all;
    }

    button.follow {
      pointer-events: all;
    }
  }
}

// user banner

$avatarSizeMobile: 7vw;

.user {
  position: relative;
  display: flex;
  gap: 1em;
  padding: .5em;
  text-decoration: none;
  flex-direction: row;
  align-items: center;

  .avatar {
    position: relative;
    width: 50px;
    height: 50px;
    flex-shrink: 0;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  // .info {
  //   display: flex;
  //   flex-wrap: wrap;
  //   align-items: center;

  //   @media (max-width: 749px) {
  //     justify-content: center;
  //   }
  //   @media (min-width: 750px) {
  //     > * {
  //       white-space: nowrap;
  //     }
  //   }
  // }

  p {
    width: calc(100% - 50px - 1em);
    margin: 0;
  }

  .displayname {
    font-weight: bold;
    white-space: nowrap;
  }

  .follow {
    height: auto;
    // margin-left: auto;
  }

  .stats {
    position: relative;
    display: flex;
    gap: .5em;
    align-items: center;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  @media (max-width: 749px) {
    box-sizing: border-box;
    padding: .3em .6em;
    gap: 8px;

    .info {
      flex-wrap: nowrap;
      width: calc(100% - $avatarSizeMobile - 8px);
      justify-content: flex-start;
      gap: 8px;
    }
    p {
      display: inline-block;
      width: auto;
    }
    .stats {
      white-space: nowrap;
    }
    .avatar {
      width: $avatarSizeMobile;
      height: $avatarSizeMobile;
    }
  }
}
