@import 'mixins.scss';

@mixin sellDark {
  --buttonBgColor: rgb(22, 27, 34);

  --borderNeutral: #888;
  --borderNegative: #444;
}
:root {
  --buttonBgColor: #fff;

  --borderNeutral: #ddd;
  --borderNegative: #444;
}
body.dark {
  @include sellDark;
}
@media (prefers-color-scheme: dark) {
  body:not(.light) {
    :root {
      @include sellDark;
    }
  }
}

.selling-images {
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  align-items: center;

  .image {
    position: relative;
    width: calc(33% - (1em / 3 * 2));
    min-height: 5em;

    img {
      position: relative;
      width: 100%;
      border-radius: 1.5vw;
  
      @media (min-width: 750px) {
        border-radius: .6825em;
      }
    }

    button.tool {
      position: absolute;
      background-color: var(--buttonBgColor);
      bottom: 1em;
      right: 1em;
      z-index: 1;

      &:hover {
        background-color: var(--buttonBgColor) !important;
      }
    }
  }
}

.image-to-delete {
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: 40% !important;
  max-height: 40vh;
  border-radius: .8em;
}

.items {
  .top-left {
    z-index: 99;
    display: flex;
    justify-content: space-between;
  
    button:first-child {
      padding-left: calc(.5rem + 2px);
      transform: translateX(calc(-1rem - 2px));
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-color: transparent;
    }

    @media (min-width: 750px) {
      button:nth-child(2) {
        display: none;
      }
    }
  }

  @media (max-width: 749px) {
    .desktop {
      display: none;
    }
  }
  
  .information {
    padding: 1rem;
    border: 1px solid $color_medgrey;
    border-radius: 1em;
    margin: 1em 0;
  }

  .slim.hide-mobile {
    margin-bottom: 0;
  }

  .preview-area {
    position: relative;
  
    .listings.single {
      pointer-events: none;
    }
  
    &.left {
      ul {
        padding: 0;
        li {
          width: 100%;
        }
      }
    }
  
    &.hide-desktop {
      flex-grow: 1;
  
      .listings.single {
        position: absolute;
        bottom: 0;
      
        display: block;
        padding-left: 0;
        margin: 0 calc(30vw - 1rem);
        width: 40vw;
      
        .listing {
          display: block;
          width: 100%;
        }
      }
    }
  }
}

.multiple-buttons {
  display: flex;
  gap: 1em;
}

.checklist {
  button, button:hover {
    width: 100%;
    font-size: 1em;
    padding: .25em 0;
    background: none;
    border: none;
    color: $colour_red;
    text-align: left;

    &:before {
      content: "・";
      position: relative;
      display: inline-block;
      margin-right: .5em;
    }

    &.done {
      color: $color_green;

      &:before {
        content: "✔";
      }
    }

    &:after {
      content: "→";
      position: relative;
      display: inline-block;
      opacity: 0;
      transition: transform .1s linear, opacity .1s linear;
    }

    &:not(:disabled):hover {
      &:after {
        opacity: 1;
        transform: translateX(.5em);
        transition: transform .25s linear, opacity .25s linear;
      }
    }
  }
}

.review {
  position: relative;
  width: 100%;
  border-radius: 1em;
  margin-top: 2em;
  box-shadow: 0 0 0 2px var(--borderNeutral);

  &.positive {
    box-shadow: 0 0 0 2px $colour_red;
  }
  &.negative {
    box-shadow: 0 0 0 2px var(--borderNegative);
  }
  
  &:first-of-type {
    margin-top: 0;
  }

  td:first-child {
    white-space: nowrap;
  }
  td {
    padding: 1em;
  }
}
