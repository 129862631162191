@import 'mixins.scss';

@mixin accountDark {
  --bgDefault: rgb(201, 209, 217);
  --colorDefault: rgb(13, 17, 23);

  --borderHr: rgb(48, 54, 61);
}
:root {
  --bgDefault: rgb(125, 125, 125);
  --colorDefault: #fafafa;

  --borderHr: #ddd;
}
body.dark {
    @include accountDark;
}
@media (prefers-color-scheme: dark) {
    body:not(.light) {
        :root {
            @include accountDark;
        }
    }
}

ul.editable li {
  border-bottom: 1px dashed var(--borderHr);
  padding: 1em;
  @media (max-width: 599px) {
    padding: 1em 0;
  }
}

ul.organised li {
  padding: .15em 1em;
  @media (max-width: 599px) {
    padding: .15em 0;
  }
}

ul.editable, ul.organised {
  padding: 0;

  &.next-level li {
    display: block;
  }

  .expired > * {
    opacity: .5;
  }

  &.next-level li div, &:not(.next-level) li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.5em;

    &:first-child {
      padding-top: 0;
    }

    strong {
      background: var(--bgDefault);
      color: var(--colorDefault);
      padding: .25em .75em;
      margin-right: .5em;
      border-radius: 1em;
      font-size: .75em;
      text-transform: uppercase;
      white-space: nowrap;

      &.red {
        background-color: $colour_red;
      }
      &.yellow {
        background-color: $color_gold;
      }
      &.green {
        background-color: $color_green;
      }
    }

    span {
      display: block;

      &.buttons {
        display: flex;
        button, .button {
          flex-wrap: nowrap;
          margin-left: .5em;
          line-height: 1em;
        }
      }
    }
  }
}