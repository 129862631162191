@import 'mixins.scss';

body.minimal_ui {
  margin: 0;
  padding: 0;

  .App {
    max-width: 100vw;
    max-height: 100vh;
    overflow: hidden;
  }
  
  main {
    margin: 0;
  }

  .onboarding-container {
    position: relative;
    padding: 0 !important;
    max-width: 100%;
    max-height: 100%;

    .header {
      position: relative;
      box-sizing: border-box;
      height: 13vh;
      padding: 5vw 0 0 5vw;

      .logo {
        position: relative;
        display: block;
        width: 33vw;
        height: 100%;
        color: transparent;
        font-size: 0;
  
        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          background: top left no-repeat;
          background-image: url("/logo.svg");
          background-size: auto;
        }
      }
    }

    &.stage-0, &.stage-1 {
      &:before {
        content: "";
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        background-color: transparent;
        z-index: 99;
        pointer-events: none;
      }

      .buttons {
        transition: opacity .2s ease-out;
      }

      .buttons.second {
        opacity: 0;
        pointer-events: none;
        z-index: 101;
      }

      .content {
        img.card {
          position: relative;
          display: block;
          margin: 0 auto;
          max-width: 80vw;
          max-height: calc(100vh - 12vh - 7em - 2em - 8vh);
          border-radius: 3.5%/2.5%;
        }

        .title {
          text-align: center;
          font-size: .75em;
        }

        .success-text {
          position: fixed;
          top: calc(65vh);
          width: 100%;
          z-index: 101;
          color: #fff;
          padding: 0 10vw;
          text-align: center;
          box-sizing: border-box;
          pointer-events: none;
          opacity: 0;
        }

        .fanfare-text {
          position: fixed;
          width: 100%;
          height: 100%;
          font-size: 3em;
          font-weight: bold;
          z-index: 100;
          top: 25%;
          left: 15%;
          transform: rotate(-15deg);
          pointer-events: none;

          div {
            position: relative;
            color: #fff;
            background: $colour_red;
            padding: .25em .75em;
            text-align: left;

            &:first-of-type {
              text-align: right;
              left: -30%;
            }
            &:last-of-type {
              right: -30%;
            }
          }
        }
      }
    }

    &.stage-0 {
      .fanfare-text {
        opacity: 0;
      }
    }
    
    @keyframes bgAnim {
      0% {
        background-color: transparent;
      }
    }
    @keyframes fanfareFromLeft {
      0% {
        left: -100%;
      }
      70% {
        left: -20%;
      }
    }
    @keyframes fanfareFromRight {
      0% {
        right: -100%;
      }
      70% {
        right: -20%;
      }
    }
    @keyframes successTextAppear {
      0% {
        opacity: 0;
      }
      50% {
        opacity: 0;
      }
    }
    @keyframes button2Appear {
      0% {
        opacity: 0;
      }
      67% {
        opacity: 0;
      }
    }

    &.stage-1 {
      &:before {
        background-color: rgba(0,0,0,.75);
        animation: bgAnim .2s ease-out;
      }

      .fanfare-text div {
        &:first-of-type {
          animation: fanfareFromLeft .2s ease-out;
        }
        &:last-of-type {
          animation: fanfareFromRight .2s ease-out;
        }
      }

      .content .success-text {
        animation: successTextAppear 1.5s ease-out;
        opacity: 1;
      }

      .buttons.first {
        opacity: 0;
        pointer-events: none;
      }

      .buttons.second {
        animation: button2Appear 3s ease-out;
        pointer-events: all;
        opacity: 1;
      }
    }

    &.stage-2 {
      .inner {
        box-sizing: content-box;
        padding: 0 5vw;
      }
    }

    .buttons {
      position: fixed;
      box-sizing: border-box;
      width: 100%;
      bottom: 0;
      padding: 1em;
      text-align: center;

      > :last-child {
        margin-bottom: 0;
      }
    }
  }
}
