.register-complete {
    .art {
        position: relative;
        width: 30vw;
        height: 28vw;
        margin: 0 auto;

        max-width: 100%;
        // max-height: 13.5rem;

        background-repeat: no-repeat;
        background-image: url('/hero/welcome.svg');
        background-size: 95% auto;
        background-position: top center;

        @media (max-width: 799px) {
            // mobile
            position: relative;
            width: 100vw;
            height: 40vh;
            margin: 0 auto 1rem;
            min-height: 90px;
            background-size: auto 100%;
        }
        @media (min-width: 800px) {
            // desktop
            max-width: 25%;
            float: left;
            margin: 0 2rem 0 0;
            display: block;
        }
    }

    @media (max-width: 799px) {
        text-align: center;
        h1 {
            margin-bottom: 0.5em;
        }
    }
    @media (min-width: 800px) {
        position: relative;
        p {
            max-width: 48rem;
        }
        h1 {
            width: 100%;
        }
        .text {
            margin-top: 13vh;
        }

        margin-top: calc(20vh - 4rem);
        margin-bottom: calc(20vh - 4rem);
        overflow-x: hidden;

        > div {
            position: relative;
            left: 50%;
            margin-left: -24rem;
        }
    }

    &:after {
        content: "";
        display: block;
        clear: left;
    }
}

@media (min-width: 750px) {
    .slim {
        display: flex;
        gap: 5%;
        margin-bottom: 2em;

        &.verify-phone form > p:nth-child(2) {
            margin-top: 0;
        }

        .left {
            width: 45%;
            display: flex;
            flex-flow: column;

            form {
                flex-grow: 1;
                display: flex;
                flex-flow: column;
            }
      
            .bottom-element {
                margin-top: auto;

                p {
                    margin-bottom: 0;
                }

                p.center {
                    display: none;
                }
            }
        }
        .right {
            width: 50%;

            &.text {
                text-align: right;
                margin-top: 4.6em;
            }
        }
        img {
            max-width: 400px;
            margin: 2em auto 0;
        }

        .left, .right {
            &.skinny {
                width: 20%;
            }
            &.fat {
                width: 75%;
            }
        }
    }
}

main.sell {
    .region-shipping-services {
        .price {
            text-align: right;
        }
    }
}
