.generic-not-found {
    .art {
        position: relative;
        width: 25vw;
        height: 22.5vw;
        margin: 0 auto;

        max-width: 15rem;
        max-height: 13.5rem;

        background-repeat: no-repeat;
        background-image: url('/hero/error.svg');
        background-size: 100% auto;
        background-position: center;

        @media (max-width: 799px) {
            // mobile
            position: relative;
            width: 100vw;
            height: 20vh;
            margin: 1rem auto 2rem;
            min-height: 90px;
            background-size: auto 100%;

            @media (min-height: 540px) {
                margin-top: calc((100vh - 500px) / 3);
            }
        }
        @media (min-width: 800px) {
            // desktop
            float: left;
            margin: 0 2rem 0 0;
            display: block;
        }
    }

    @media (max-width: 799px) {
        text-align: center;
        h1 {
            display: none;
        }
    }
    @media (min-width: 800px) {
        position: relative;
        p {
            max-width: 48rem;
        }
        h1 {
            padding-top: 1rem;
        }

        margin-top: calc(25vh - 4rem);
        margin-bottom: calc(25vh - 4rem);
        overflow-x: hidden;

        > div {
            position: relative;
            left: 50%;
            margin-left: -24rem;
        }
    }

    &:after {
        content: "";
        display: block;
        clear: left;
    }
}
