@use 'sass:math';
@import 'mixins.scss';

@mixin setsDark {
    --bgSet: transparent;
    --bgSetListEven: rgb(22, 27, 34);
    --borderList: rgb(105, 117, 128);
    --sliderFadeLeft: linear-gradient(to left, rgba(13, 17, 23,0), rgba(13, 17, 23,1));
    --sliderFadeRight: linear-gradient(to right, rgba(13, 17, 23,0), rgba(13, 17, 23,1));
    --arrow: rgb(105, 117, 128);
}
:root {
    --bgSet: $color_lightgrey;
    --bgSetListEven: #fff;
    --borderList: #ddd;
    --sliderFadeLeft: linear-gradient(to left, rgba(255,255,255,0), rgba(255,255,255,1));
    --sliderFadeRight: linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1));
    --arrow: #bbb;
}
body.dark {
    @include setsDark;
}
@media (prefers-color-scheme: dark) {
    body:not(.light) {
        :root {
            @include setsDark;
        }
    }
}

.sets-container {
    margin-bottom: .5rem;

    ul {
        list-style-type: none;
        padding: 0;

        li {
            position: relative;
        }
    }

    &:not(.panels) {
        ul {
            border: 2px solid var(--borderList);
            border-radius: .75rem;

            li {
                position: relative;
                display: block;
                margin: 0;
                border-bottom: 1px solid var(--borderList);

                &:nth-child(2n) {
                    background: var(--bgSetListEven);
                }
                &:last-child {
                    border-bottom: none;
                    border-radius: 0 0 .75rem .75rem;
                }

                p {
                    margin: 0;
                }

                time {
                    margin-right: .5em;
                }

                a {
                    display: block;
                    padding: .65em 2em .65em .65em;
                    text-decoration: none;
                    color: inherit;
                }

                &:before, &:after {
                    display: block;
                    content: "";
                    width: 12px;
                    height: 4px;
                    background: var(--arrow);
                    position: absolute;
                    top: 50%;
                    right: .65em;
                    border-radius: 1px;
                }

                &:before {
                    transform: rotate(45deg);
                    margin-top: -3px;
                }
                &:after {
                    transform: rotate(-45deg);
                    margin-top: 3px;
                }
            }
        }
    }

    &.panels {
        .react-multiple-carousel__arrow {
            background-color: $colour_red;

            &:hover {
                background-color: #fff !important;
                &:before {
                    color: $colour_red;
                }
            }
        }
        .swiper:not(.touchDevice) {
            // &.dragging {
            //     touch-action: none;
            // }

            &:before, &:after {
                content: "";
                position: absolute;
                display: block;
                top: 0;
                width: 10%;
                height: 100%;
                pointer-events: none;
                opacity: 0;
                transition: opacity .4s linear;
                z-index: 2;
            }
            &:before {
                left: 0;
                background-image: var(--sliderFadeLeft);
            }
            &:after {
                right: 0;
                background-image: var(--sliderFadeRight);
            }
            &.leftFadeVisible:before, &.rightFadeVisible:after {
                opacity: 1;
            }
        }

        .sets-list, .swiper {
            .set-outer, .sets-column {
                width: 100%;

                @media (max-width: 519px) {
                    width: calc(50% - 2px);
                }
                @media (min-width: 520px) and (max-width: 719px) {
                    width: calc((100%/3) - 3px);
                }
                @media (min-width: 720px) and (max-width: 959px) {
                    width: calc((100%/4) - 3px);
                }
                @media (min-width: 960px) and (max-width: 1219px) {
                    width: calc((100%/5) - 4px);
                }
                @media (min-width: 1220px) {
                    width: calc((100%/6) - ((4px * 5) /6));
                }
            }

            flex-wrap: wrap;
        }

        .swiper {
            .swiper-button-prev,
                .swiper-button-next {
                display: block;
                background-color: $colour_red;
                pointer-events: all;
                width: 3rem;
                height: 3rem;
                border-radius: 3rem;
                transition: opacity .25s linear;

                &:after {
                    display: block;
                    width: 100%;
                    text-align: center;
                    color: #fff;
                    font-size: 1.25rem;
                    margin-top: .875rem;
                }
            }
            .swiper-button-disabled {
                pointer-events: none;
                opacity: 0;
            }
        }

        .sets-list {
            gap: 4px;
        }

        .swiper > .swiper-wrapper,
            .sets-list {
            position: relative;
            display: flex;

            .set {
                position: relative;
                display: block;
                width: 100%;
                padding-bottom: 50%;
                font-size: 8pt;
                margin-bottom: 4px;
                background: var(--bgSet);
                border-radius: 4px;

                background-size: 100% auto;
                background-repeat: no-repeat;

                animation: panelAppear .25s linear;

                &:before {
                    content: "";
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background-image: linear-gradient(to bottom left, rgba(0,0,0,0), rgba(0,0,0,0.5)), linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.5));
                    border-radius: 4px;
                }

                &.soon a:after, &.new a:after {
                    display: block;
                    position: absolute;
                    width: 100%;
                    background-color: $colour_red;
                    color: #fff;
                    font-weight: bold;
                    text-align: center;
                    top: 0;
                    left: 50%;
                    padding: .5em 0;
                    line-height: 1em;
                    transform: translate(-2em, 1em) rotate(45deg);
                    pointer-events: none;
                }
                &.soon a:after {
                    content: "近日発売";
                }
                &.new a:after {
                    content: "NEW";
                    transform: translate(-1.5em, .6em) rotate(45deg);
                }

                &.soon a p span.small {
                    background: $colour_red;
                }

                a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    color: #fff;
                    text-decoration: none;
                    overflow: hidden;

                    p {
                        position: absolute;
                        margin: 0 4px 4px;
                        bottom: 0;

                        span.small {
                            display: inline-block;
                            font-size: 6pt;
                            background: rgba(0,0,0,.5);
                            padding: 2px 6px;
                            border-radius: 2px;
                        }
                        span {
                            position: relative;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}

@keyframes panelAppear {
    0% {
        opacity: 0;
    }
}
