@import 'mixins.scss';

@mixin messagesDark {
  --bgMyMessage: rgb(48, 54, 61);
  --bgTheirMessage: rgba(246, 46, 54, .5);
}
:root {
  --bgMyMessage: #eaeaea;
  --bgTheirMessage: rgba(246, 46, 54, .33);
}
body.dark {
  @include messagesDark;
}
@media (prefers-color-scheme: dark) {
  body:not(.light) {
    :root {
      @include messagesDark;
    }
  }
}

.emoji {
  position: relative;
  display: inline-block;
  width: 1.2em;
  height: 1.2em;
  vertical-align: -20%;
  > * {
    position: relative;
    width: 100%;
    height: 100%;
  }
}

.messages {
  .message {
    position: relative;

    .details {
      font-size: .8em;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      margin: .25em 1.25em;

      .username {
        font-weight: bold;
      }
      time {
        color: #aaa;
      }
    }

    &.mine .details {
      text-align: right;
      justify-content: flex-end;
    }

    .body {
      display: flex;
      white-space: pre-wrap;

      div {
        position: relative;
        display: inline-block;
        padding: .5em 1em;
        border-radius: 1em;
        background: var(--bgTheirMessage);
      }
    }

    &.mine .body {
      justify-content: flex-end;

      div {
        background: var(--bgMyMessage);
        color: inherit;
      }
    }

    margin-bottom: .25em;
    &.lastInChain {
      margin-bottom: 1em;

      &:not(.mine) .body div {
        border-bottom-left-radius: 0;
      }
      &.mine .body div {
        border-bottom-right-radius: 0;
      }
    }
  }

  .send-button {
    display: flex;
    justify-content: flex-end;
  }
}