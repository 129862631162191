@mixin cardsConditionDark {
  --bgConditionHelp: rgb(10, 12, 16);
  --borderConditionHelp: rgb(105, 117, 128);
}
:root {
  --bgConditionHelp: #fff;
  --borderConditionHelp: #bbb;
}

body.dark {
  @include cardsConditionDark;
}

.condition-form {
  display: flex;

  > :not(:last-child) {
    margin-right: 2rem;
  }

  > div {
    flex: calc(100%/3);
  }

  .helper {
    position: relative;
    margin-left: .5rem;
  }
}

.condition-help {
  background: var(--bgConditionHelp);
  border-radius: .5rem;
  padding: .5rem 2rem;
  border: 1px solid var(--borderConditionHelp);
  display: block;
  position: relative;
  width: 67%;
  margin: 0 auto;

  img {
    position: relative;
    display: block;
    max-width: 100%;
    max-height: 25rem;
    margin: 1rem auto;
  }
}

.condition-container {
  position: relative;
}