@use 'sass:math';
@import 'mixins.scss';

@mixin cardsListDark {
    --bgCard: rgb(13, 17, 23);
    --bgCardOwned: rgba(246, 194, 46, .15);
    --boxshadowCard: inset 0 0 0 1px rgb(48, 54, 61);

    --color: #fff;

    --colorPrice: #000;
    --no-image-opacity: .33;

    --colorHeader: #000;

    --bgFilters: rgba(13, 17, 23, 0.8);
    --bgFiltersButton: rgb(13, 17, 23);
}

:root {
    --bgCard: #fff;
    --bgCardOwned: #fff;
    --boxshadowCard: inset 0 0 0 1px #ddd;

    --color: #000;

    --colorPrice: #fff;
    --no-image-opacity: 1;

    --colorHeader: #fff;

    --bgFilters: rgba(255, 255, 255, 0.95);
    --bgFiltersButton: #fff;
}
body.dark {
    @include cardsListDark;
}
@media (prefers-color-scheme: dark) {
    body:not(.light) {
        :root {
            @include cardsListDark;
        }
    }
}

main.categories {
    padding-top: 0;

    @media (max-width: 599px) {
        padding: 0 0 1rem;
        > div:not(.pokemon),
            .pokemon > div:last-of-type {
            padding: 4px;
        }
    }

    > div:not(.pokemon) {
        padding-top: 1rem;
    }
}

$headerColors: (
    "black": #585858,
    "blue": #3088f0,
    "brown": #b07030,
    "gray": #a0a0a0,
    "green": #40b868,
    "pink": #f890c8,
    "purple": #a868c0,
    "red": #f05868,
    "white": #f0f0f0,
    "yellow": #f0d048,
);

$headerColorsText: (
    "white": #585858,
    "yellow": #3B3418,
);

@each $key, $val in $headerColors {
    .header-pokemon.#{$key} {
        background-color: $val;

        .forms {
            color: $val;
            li > * {
                border-color: $val !important;
            }
        }

        // h1 {
        //     -webkit-text-stroke: .25em $val;
        //     paint-order: stroke fill;
        // }

        .inner {
            button, .button-count {
                background: rgba($val,.9);
            }
        }
    }
}

@each $key, $val in $headerColorsText {
    .header-pokemon.#{$key} {
        > *:not(.modal-container) {
            color: $val;
        }

        .forms {
            a {
                color: $val;
            }
        }

        .inner {
            button.tool {
                color: $val;
                border-color: $val;

                &:hover {
                    background-color: rgba(0,0,0,.33) !important;
                }
            }

            .button-count {
                border-color: $val;
                &:before {
                    border-right-color: $val;
                }
            }
        }
    }

    body:not(.dark) {
        .header-pokemon.#{$key} {
            .forms {
                color: $val !important;
            }
        }
    }
}

.header-pokemon-below {
    position: relative;
    margin: 2.5em 1em 0;

    @media (min-width: 600px) {
        display: none;
    }

    .flavortext {
        font-style: italic;
        font-size: .8em;
    }
}

.header-pokemon {
    > *:not(.modal-container) {
        color: var(--colorHeader);
    }

    transition: background-color .33s linear;
    position: relative;

    @media (min-width: 600px) {
        margin-bottom: 3em;
        border-radius: 0 0 2em 2em;
    }

    h1 {
        margin-bottom: .25em;

        span {
            display: inline-block;
        }

        span.number {
            color: rgba(0,0,0,.33);
        }

        @media (min-width: 600px) {
            font-size: 3em;
        }
        @media (max-width: 599px) {
            width: 60%;
        }
    }

    h2 {
        font-weight: normal;
        margin: .25em 0;
        font-size: 1em;

        &:last-of-type {
            margin-bottom: 1.5em;
        }

        @media (min-width: 600px) {
            font-size: 1.5em;
        }
    }

    .flavortext {
        max-width: 30%;
        font-style: italic;

        @media (max-width: 599px) {
            &.desktop {
                display: none;
            }
        }
    }

    $evolutionHeight: 60;
    $evolutionHeightMobile: 40;

    .inner {
        position: relative;
        overflow: hidden;
        padding: 0 2.5em 1.5em;

        @media (max-width: 599px) {
            padding: 0 1em 1em;
        }

        .content {
            display: flex;
            flex-direction: column;

            @media (max-width: 600px) {
                min-height: calc(66vh - 4rem - 72px - #{$evolutionHeightMobile}px);
            }
        }

        > *:not(.background-image) {
            position: relative;
            z-index: 1;
        }

        button.tool {
            color: var(--colorHeader);
            border-color: var(--colorHeader);

            &:hover {
                background-color: rgba(0,0,0,.33) !important;
            }
        }

        .button-count {
            border-color: var(--colorHeader);
            &:before {
                border-right-color: var(--colorHeader);
            }
        }
    }

    .follow-area {
        flex-grow: 2;
        display: flex;

        @media (max-width: 599px) {
            margin-bottom: 1em;
        }

        * {
            display: inline-block;
            align-self: flex-end;
        }
    }

    .background-image {
        position: absolute;
        pointer-events: none;

        @media (min-width: 600px) {
            top: -10%;
            right: 5%;
            height: 120%;
        }

        @media (max-width: 599px) {
            left: 75%;
            max-width: 70%;
            top: 50%;
            margin-top: 2.5vh;
            transform: translateY(-50%) translateX(-50%);
        }
    }

    .forms {
        display: flex;
        list-style-type: none;
        padding: 0;
        position: absolute;
        bottom: 0;
        margin-bottom: calc(-1.25em - 2px);
        left: 50%;
        transform: translateX(-50%);
        background-color: inherit;
        border-radius: .5em;

        @media (max-width: 599px) {
            font-size: .8em;
        }

        li {
            display: inline-block;
            white-space: nowrap;
            line-height: 1em;

            > * {
                display: block;
                padding: .75em 1.5em;
                border-top: 2px solid;
                border-bottom: 2px solid;
            }

            &.selected > span {
                background: var(--colorHeader);
            }

            &:first-child > * {
                border-top-left-radius: .5em;
                border-bottom-left-radius: .5em;
                border-left: 2px solid;
            }

            &:last-child > * {
                border-top-right-radius: .5em;
                border-bottom-right-radius: .5em;
                border-right: 2px solid;
            }

            a {
                text-decoration: none;
                color: var(--colorHeader);
            }
        }
    }

    .evolutions-outer {
        z-index: 1;
        position: relative;

        @media (max-width: 599px) {
            width: calc(100% + 2em);
            left: -1em;
        }
        @media (min-width: 600px) {
            width: calc(100% + 5em);
            left: -2.5em;
        }

        display: flex;
        justify-content: space-between;

        .evolutions {
            display: flex;
            height: #{$evolutionHeight}px;
        }

        .evolution {
            position: relative;
            // max-width: 45vw;
            height: #{$evolutionHeight}px;
            white-space: nowrap;

            &.previous {
                margin-right: #{math.div($evolutionHeight, 2) + 5}px;
            }

            &.next {
                margin-left: #{math.div($evolutionHeight, 2) + 5}px;
            }

            > * {
                position: relative;
                display: block;
                padding: 0 2em 0 5em;
                height: #{$evolutionHeight}px;
                font-size: #{math.div($evolutionHeight, 4)}px;
                background: rgba(0,0,0,.33);
                line-height: #{$evolutionHeight}px;
                color: #fff;
                z-index: 1;
            }

            img {
                position: absolute;
                left: 2.5em;
                top: 50%;
                transform: translateY(-50%) translateX(-50%);
                min-height: 50%;
                max-height: 80%;
            }

            a {
                text-decoration: none;
            }

            &.first, &.next:not(.last) {
                z-index: 1;
                > * {
                    padding-left: 5.5em;
                    img {
                        left: 3em;
                    }
                }
            }
        }

        .evolution > *:after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: -#{math.div($evolutionHeight, 2)}px;
            border-top: #{math.div($evolutionHeight, 2)}px solid transparent; 
            border-bottom: #{math.div($evolutionHeight, 2)}px solid transparent;
            border-left: #{math.div($evolutionHeight, 2)}px solid rgba(0,0,0,.33);
            z-index: 0;
        }

        .evolution > *:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: -#{math.div($evolutionHeight, 2)}px;
            border-top: #{math.div($evolutionHeight, 2)}px solid rgba(0,0,0,.33); 
            border-bottom: #{math.div($evolutionHeight, 2)}px solid rgba(0,0,0,.33);
            border-left: #{math.div($evolutionHeight, 2)}px solid transparent;
            z-index: 0;
        }

        @media (max-width: 599px) {
            .evolutions {
                height: #{$evolutionHeightMobile}px;
            }

            .evolution {
                height: #{$evolutionHeightMobile}px;
    
                &.previous {
                    margin-right: #{math.div($evolutionHeightMobile, 2) + 5}px;
                }
    
                &.next {
                    margin-left: #{math.div($evolutionHeightMobile, 2) + 5}px;
                }
    
                > * {
                    height: #{$evolutionHeightMobile}px;
                    font-size: #{math.div($evolutionHeightMobile, 4)}px;
                    line-height: #{$evolutionHeightMobile}px;
                }
            }
    
            .evolution > *:after {
                right: -#{math.div($evolutionHeightMobile, 2)}px;
                border-top-width: #{math.div($evolutionHeightMobile, 2)}px;
                border-bottom-width: #{math.div($evolutionHeightMobile, 2)}px;
                border-left-width: #{math.div($evolutionHeightMobile, 2)}px;
            }

            .evolution > *:before {
                left: -#{math.div($evolutionHeightMobile, 2)}px;
                border-top-width: #{math.div($evolutionHeightMobile, 2)}px;
                border-bottom-width: #{math.div($evolutionHeightMobile, 2)}px;
                border-left-width: #{math.div($evolutionHeightMobile, 2)}px;
            }
        }

        .evolution img {
            image-rendering: pixelated;
            image-rendering: -webkit-optimize-contrast;
        }
    }
}

.breadcrumbs > *:not(:last-child) {
    margin-right: 1.5em;
    position: relative;

    &:after, &:before {
        position: absolute;
        top: 50%;
        right: -.8em;
        display: block;
        content: "";
        width: 2px;
        height: 6px;
        background: #777;
        transform: rotate(-45deg);
        margin-top: -4px;
    }
    &:before {
        transform: rotate(45deg);
        margin-top: -1px;
    }
}

ul.cards-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: auto;

    display: flex;
    flex-wrap: wrap;
  
    li.card {
        animation: cardAppear .25s ease-out;
        position: relative;
        padding: 1em;
        box-sizing: border-box;
        background: var(--bgCard);
        border-radius: .25em;
        margin-bottom: 1em;

        width: calc(50% - .5em);
        margin-left: 1em;

        > * {
            width: 100%;
        }

        .sale-price {
            position: absolute;
            background: $colour_red;
            color: var(--colorPrice);
            display: block;
            width: auto;
            font-size: 1rem;
            font-weight: bold;
            padding: .5em 1em;
            top: 0;
            right: 0;
            box-shadow: .25em .25em 0 0 rgba(0,0,0,.5);
            border-radius: 0 .25em 0 0;
            z-index: 1;
        }
        
        &.editing {
            border: 2px dashed $colour_red;
            box-shadow: none;
        }

        // &.moreThanOneItemData {
        //     box-shadow: inset 0 0 0 5px red !important;
        // }

        // &.oneItemData {
        //     box-shadow: inset 0 0 0 5px greenyellow !important;
        // }

        // &.noItemData {
        //     box-shadow: inset 0 0 0 5px palevioletred !important;
        // }

        .button > button, .button > span {
            background: inherit;
            &:not(.add):not(.remove-collection) {
                background: rgba(246,194,46,.15);
                // text-shadow: 0 1px 0 #fff, 0 -1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff;
            }
        }

        background-size: 100% auto;
        overflow: hidden;

        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;

        transition: box-shadow .15s ease-out, opacity .25s ease-out;
        box-shadow: var(--boxshadowCard);

        &.owned {
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                border: 1.5em solid $color_gold;
                border-right-color: transparent;
                border-bottom-color: transparent;
                z-index: 0;
            }
            // box-shadow: inset 0 0 0 1px $color_gold;
            background: var(--bgCardOwned);
        }

        .item-name {
            z-index: 1;
            display: flex;
            justify-content: space-between;
            align-items: baseline;
            overflow: hidden;

            h3, h4 {
                font-size: .8em;
                display: inline-block;
                margin: 0;
                font-weight: inherit;
            }
            h3 {
                margin: 0 0 .75em;
            }
            h4 {
                font-weight: 600;
                background: $color_lightgrey;
                border-radius: 2px;
                padding: .1em .5em;
                color: $color_greyfont;
                max-width: 4em;
                white-space: nowrap;
                text-overflow: ellipsis;
                text-align: center;
            }
        }

        .item-actions {
            display: flex;
            justify-content: space-between;

            button, a {
                width: 100%;
            }
        }

        &.can-collect .item-actions {
            :first-child {
                margin-right: .5em;
            }
        }

        &.selected {
            box-shadow: inset 0 0 0 2px $colour_red;
            background: rgba($colour_red, .25);

            animation: selectedCard .5s ease-in-out infinite alternate;

            .images {
                opacity: .5;
            }
        }
        &.selectable {
            cursor: pointer;

            .images {
                > * {
                    pointer-events: none;
                }
            }
        }

        &.no-unset-condition {
            opacity: .25;
            pointer-events: none;

            button:disabled {
                opacity: 1;
                border-color: $color_disabled;
                color: $color_disabled;
            }
        }

        .images {
            opacity: 0;
            transition: all .25s linear;
            position: relative;

            &.multiple {
                cursor: pointer;
            }
            &.loaded {
                opacity: 1;
            }
            img {
                width: 100%;
                border-radius: .4rem;
                height: auto;
            }
            .no-image {
                position: relative;
                display: block;
                color: rgba(0,0,0,0);
                font-size: 0;
                width: 100%;
                padding-bottom: 141.42857143%;
                background-image: url('/no_image.png');
                background-size: 100% auto;
                // opacity: .75;
                opacity: var(--no-image-opacity);
            }
            img, .no-image {
                margin-bottom: .5rem;
            }
        }

        .change-image {
            float: left;
            background: transparent;
            background-position: center right;
            background-size: 90% auto;
            background-repeat: no-repeat;
            border: 0;
            padding: 0;
            width: 40px;
            height: 1em;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: 1em;

            &.right, &.left {
                background-image: url("/arrow.svg");
            }

            &.left {
                transform: rotate(180deg);
            }
        }

        .title {
            display: flex;
            justify-content: space-between;
        }

        > span, > p, .flex > p, form p {
            font-size: .75em;
            display: block;
            text-align: center;
        }

        > span, > p, .flex > p {
            margin: 0 auto .5rem;
        }

        span.variant {
            margin-right: .5em;
            text-transform: uppercase;
            font-size: .8em;
            font-weight: 600;
            color: $color_greyfont;
            box-shadow: 0 0 0 1px $color_lightgrey;
            padding: 0 .35em;
            border-radius: .5em;
        }

        span {
            display: inline-block;
        }

        a.ellipsis {
            display: block;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        select {
            display: block;
            width: 100%;
            margin-bottom: .5rem;
            margin-top: auto;
        }

        .button-container.no-condition {
            margin-top: auto;
        }

        form {
            > *:first-child, > *:last-child {
                margin: 0;
            }
        }

        > button, > .button {
            &:not(:last-child) {
                margin-bottom: .5rem;
            }
        }

        button {
            // display: block;
            // background: $colour_red;
            // color: #fff;
            // border: none;
            // font-size: inherit;
            // padding: .5em 0;
            // border-radius: .25em;
            // cursor: pointer;
            transition: opacity .2s linear, border-color .15s ease-out, color .15s ease-out;
            &:disabled {
                opacity: .5;
            }
        }

        button.gold, .button.gold {
            border-color: $color_gold;
            color: $color_gold;

            button, span {
                &:not(:first-child) {
                    box-shadow: -1px 0 0 0 #F6C22E, inset 1px 0 0 0 #F6C22E, 0 0 0 1px #F6C22E;
                }
                &:first-child {
                    box-shadow: 0 0 0 1px #F6C22E;
                }
            }

            button:hover {
                background: $color_gold;
                color: #fff;
            }
        }
    }

    li.card {
        @media (max-width: 519px) {
            &:nth-child(2n-1) {
                margin-left: 0;
            }
            width: calc(50% - .5em);
        }
        @media (min-width: 520px) and (max-width: 719px) {
            &:nth-child(3n-2) {
                margin-left: 0;
            }
            width: calc((100%/3) - .667em);
        }
        @media (min-width: 720px) and (max-width: 959px) {
            &:nth-child(4n-3) {
                margin-left: 0;
            }
            width: calc((100%/4) - .75em);
        }
        @media (min-width: 960px) and (max-width: 1219px) {
            &:nth-child(5n-4) {
                margin-left: 0;
            }
            width: calc((100%/5) - .8em);
        }
        @media (min-width: 1220px) {
            &:nth-child(6n-5) {
                margin-left: 0;
            }
            width: calc((100%/6) - .834em);
        }
    }
}

ul.cards-list, ul.listings {
    &.loading li {
        animation: loading .5s ease-in-out infinite alternate, cardAppear .25s linear;
    }
}

ul.listings {
    position: relative;
}

.game-select {
    // unstyle ul
    list-style-type: none;
    margin: 0 2vw;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    gap: clamp(4px, 4vw, 3em);

    li {
        display: flex;
        align-items: center;
        max-width: 150px;

        // 3 per row, with some space on the sides
        flex: 1 0 calc(100%/3 - 2.67vw);

        cursor: pointer;
        &.selected {
            cursor: default;
        }
        
        img {
            width: 100%;
        }

        h3 {
            margin: .5em 0 0;
            font-size: .6rem;
            font-weight: normal;
            color: var(--color);
            text-align: center;
        }
    }
}

@keyframes loading {
    0% {
        opacity: .4;
    }
    100% {
        opacity: .2;
    }
}

.toolbar {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 1em;
    
    @media (max-width: 719px) {
        padding: 0 1em;
    }

    .batch-buttons {
        margin-left: auto;
    }
    h2 {
        margin: 0;
    }
    &:after {
        content: "";
        display: block;
        clear: both;
    }
}

.selected-filters {
    position: sticky;
    top: min($header_height_desktop, $header_height_desktop_max);
    z-index: 90;
    transition: opacity .15s linear;
    background: var(--bgFilters);

    @media (min-width: 719px) {
        box-sizing: border-box;
        padding: .5em 0;
    }
    
    @media (max-width: 719px) {
        top: $header_height_mobile;
        box-sizing: border-box;
        width: calc(100% + 8px);
        margin-left: -4px;
        padding: .5em 1em;
    }

    flex: 0 0 100%;
    margin-bottom: 1em;

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: baseline;
    gap: .5em;

    // &.filterMenuDisplayed {
    //     opacity: 0;
    // }

    > strong {
        padding: .1em 0;
    }

    > span {
        padding: .1em .5em;
        border-radius: .25em;
        transition: opacity .15s linear;

        &.canclear {
            cursor: pointer;

            &:after {
                content: "×";
                margin-left: .5em;
                display: inline-block;
            }
    
            &:hover {
                opacity: .5;
            }
        }
    }

    button {
        background: var(--bgFiltersButton);
    }
}

@keyframes selectedCard {
    0% {
        background: rgba($colour_red, .1);
    }
    100% {
        background: rgba($colour_red, .25);
    }
}

@keyframes cardAppear {
    0% {
        opacity: 0;
    }
}
