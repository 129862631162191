@import 'mixins.scss';

@mixin unitDark {
  --colorPrivacyText: #000;
  --invertPrivacyIcon: invert(1);
  --bgItemDetails: rgba(0,0,0,.9);
}
:root {
  --colorPrivacyText: #fff;
  --swiper-theme-color: #EE3942;
  --swiper-pagination-color: #EE3942;
  --swiper-navigation-color: #EE3942;
  
  --bgItemDetails: rgba(255,255,255,.95);
}

body.dark {
  @include unitDark;
}
@media (prefers-color-scheme: dark) {
  body:not(.light) {
    :root {
      @include unitDark;
    }
  }
}

main.u {
  @media (max-width: 640px) {
    padding: 0;

    .user-item > *:not(.images-container):not(.modal-container), > .narrow {
      padding-left: 1em;
      padding-right: 1em;
    }

    .user-header {
      position: relative;
      display: block;
      padding: 1em 1em 0 1em;
    }
  }
}

.u > .collection {
  @media (max-width: 749px) {
    margin-top: 2.5em;
    border-top: 1px solid var(--borderHr);
    padding: 4px;
    padding-top: 2.5em;
  }
}

.user-item {
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  
  @media (min-width: 750px) {
    gap: 2em;
  }

  > * {
    width: 100%;
  }

  @media (max-width: 749px) {
    &:before {
      content: "";
      position: absolute;
      width: 100%;
      height: $header_height_mobile;
      top: 0;
      background: rgba(0,0,0,.15);
    }
  }

  @media (min-width: 750px) {
    flex-wrap: nowrap;
    .images-container {
      width: 55%;
    }
    .user-item-right {
      width: 45%;
      min-width: 360px;
    }
  }

  .grade {
    display: inline-block;
  }

  .private-notice {
    display: inline-block;
    background: $colour_red;
    color: var(--colorPrivacyText);
    width: 100%;
    box-sizing: border-box;
    text-align: center;
    font-size: 1rem;
    font-weight: bold;
    padding: calc(.4em + 2px);
    margin-top: 1em;
  }

  .description {
    white-space: pre-line;
  }

  @media (max-width: 749px) {
    .description-desktop {
      display: none;
    }
  }
  @media (min-width: 750px) {
    .description-mobile {
      display: none;
    }
  }

  @media (min-width: 750px) {
    .item-details.listing > div {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-evenly;
      width: 360px;

      .sale-price {
        order: 1;
        margin: 0;

        &.subscribe {
          margin-bottom: .5em;
        }
      }

      .quantity-select {
        order: 2;
      }

      .buy-buttons {
        order: 3;
        position: relative;
        width: 100%;

        > span {
          position: relative;
          display: block;

          &:nth-of-type(2) {
            margin-top: 2em;

            &:before {
              content: "";
              position: absolute;
              width: 90%;
              height: 1px;
              top: -1em;
              left: 5%;
              background: var(--borderHr);
            }
          }

          .button-information {
            display: block;
            font-size: .8em;
            margin-top: .5em;
            text-align: center;
          }
        }

        button {
          position: relative;
          display: block;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 749px) {
    .item-details {
      &:not(.mine) {
        position: fixed;
        width: calc(100% - 5vw);
        z-index: 79;
        bottom: 0;
        left: 2.5vw;
        pointer-events: none;
        display: none;

        &.listing {
          display: block;
        }
      }

      &:not(.mine) > div {
        pointer-events: all;
        background-color: var(--bgItemDetails);
        box-shadow: 0 2px 15px rgba(0,0,0,.2), inset 0 0 0 1px rgba(255,255,255,.2);
        box-sizing: border-box;
        border-radius: 2em;
        margin-bottom: 1.5vh;
        display: flex;
        flex-wrap: wrap;
        gap: .5em;
        padding: .5em;
        justify-content: space-evenly;
        flex-direction: row;
        align-items: center;
      
        > * {
          min-width: 0; /* Fixes Safari's min-width auto issue */
        }
      }

      > div > * {
        margin: 0;
      }

      // .title-condition {
      //   order: 1;
      //   width: 100%;
      //   text-align: center;

      //   > * {
      //     display: inline-block;
      //     margin: 0;
      //     font-size: 1em;
      //     margin: 0 .5em;
      //   }

      //   .grade {
      //     font-size: .75em;
      //   }
      // }

      .buy-buttons {
        order: 3;
        flex-grow: 2;
        display: flex;
        align-items: flex-start;
        gap: .5em;

        @media (max-width: 599px) {
          width: 100%;
        }

        > * {
          // width: calc(60% - .25em);
          flex-grow: 1;
          font-size: 1.2em; 
          // max-width: 60vw;
        }

        .button-container:nth-of-type(2) {
          border-left: 1px solid var(--borderHr);
          padding-left: .5em;
          margin-left: .15em;
        }

        .button-container > * {
          width: 100%;
          display: block;
          text-align: center;
        }
      }

      .button-information {
        order: 4;
        width: 100%;
        text-align: center;
        font-size: .8em;
        margin-top: .25em;
        color: var(--colorTextExtraLight);
      }

      &:not(.mine) {
        .sale-price {
          order: 2;
          // width: calc(40% - .25em);
          // flex-grow: 1;
          text-align: center;
        }

        // // The below causes the "buy" section to display above the bottom navigation bar
        // &:after {
        //   content: "";
        //   font-size: 2.5vw;
        //   position: relative;
        //   display: block;
        //   width: 100%;
        //   max-height: $footer_height_mobile;
        //   height: calc(10vh - 2em);
        //   min-height: 13vw;
        //   padding: 2vw 0;
        //   margin-bottom: 2vw;
        //   margin-top: 1px;
        // }
      }
    }
  }

  .quantity-select {
    margin: 1em 0;
  }

  .sale-notice {
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 1em;
    text-align: center;
  }

  .swiper-pagination-bullet {
    box-shadow: 0 0 0 1px rgba(255,255,255,.75);
  }

  .images-container {
    // border: 1px solid rgba(0,0,0,.2);
    overflow: hidden;
    user-select: none;

    @media (min-width: 750px) {
      height: calc(100vh - $header_height_desktop_max - 2em);

      > .images {
        display: flex;
        gap: 1vw;

        &.mobile {
          display: none;
        }
      }

      .main {
        border-radius: .5em;
      }
    }
    @media (max-width: 749px) {
      height: calc(90vh - $header_height_mobile - $footer_height_mobile - 4vh - 2em);

      > .images {
        &.desktop {
          display: none;
        }
      }
    }

    > .images {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .thumbs {
      margin: 0;

      @media (min-width: 750px) {
        width: 8vw;
        max-width: 100px;
      }
      @media (max-width: 749px) {
        height: 10vh;
        margin-top: 4px;
        padding: 0 4px;
      }

      .swiper-slide {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.075);
        border-radius: .25em;
        border: 1px solid rgba(0,0,0,.1);
        box-sizing: border-box;
        cursor: pointer;
      }

      img {
        position: relative;
        max-width: 100%;
        max-height: 100%;
      }
    }

    .main {
      position: relative;
      width: 100%;
      height: 100%;
      background: rgba(0,0,0,.075);

      @media (min-width: 750px) {
        width: calc(100% - 8vw);
      }
      @media (max-width: 749px) {
        height: 100%;
      }

      .swiper-slide {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
      }

      img {
        position: relative;
        max-width: 100%;
        max-height: 100%;
      }

      .swiper-slide-active img {
        box-shadow: 0 0 3em rgba(0,0,0,.25);
      }
    }

    .no-image {
      display: block;
      position: relative;
      width: 100%;
      text-align: center;
      // background: url('/img/snow.gif') center center repeat-x;
      // background-size: contain;
      background: linear-gradient(to bottom right, rgba(0,0,0,0), rgba(0,0,0,.1));
      background-color: #ddd;
      padding: 10rem 0;
      color: #fff;
      border-radius: 3px;
      font-weight: bold;
      font-size: 1.4em;
      text-shadow: 0 1px .25em rgba(0,0,0,.5);
    }
  }

  .user {
    border: 1px solid #ddd;
    border-radius: 4em;
  }

  table {
    @media (max-width: 749px) {
      width: 100%;
    }

    tr {
      border-bottom: 0;
    }
    td {
      padding: .5em 1em .5em 0;
    }
  }

  // .photos {
  //   ul {
  //     list-style-type: none;
  //     padding: 0;
  //     display: flex;
  //     justify-content: flex-start;
  //     flex-wrap: wrap;
  //     align-items: flex-end;
  //     gap: 1em;
  //   }
  //   li {
  //     display: block;
  //     @media (min-width: 761px) {
  //       width: calc(25% - .75em);
  //       &:nth-of-type(4) {
  //         margin-right: 0;
  //       }
  //     }
  //     @media (min-width: 551px) and (max-width: 760px) {
  //       width: calc(33.2% - .75em);
  //       &:nth-of-type(3) {
  //         margin-right: 0;
  //       }
  //     }
  //     @media (max-width: 550px) {
  //       width: calc(50% - .75em);
  //       &:nth-of-type(2) {
  //         margin-right: 0;
  //       }
  //     }
  //   }
  //   img {
  //     display: block;
  //     width: 100%;
  //     border-radius: .3em;
  //   }
  // }
}